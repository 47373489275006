import { Text } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

import styled from '@emotion/styled/macro';

const ShadowText = styled(Text)`
  text-shadow: ${themeGet('shadows.text')};
`;

ShadowText.displayName = 'ShadowText';

export default ShadowText;
