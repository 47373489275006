import React from 'react';

import { ActionStates } from 'src/components/ActionStates';
import { PublishPropertyDocument } from 'src/graphql/mutations/PublishProperty';
import { useMutation } from '@apollo/client';

interface IProps {
  propertyId: number;
  propertyName: string;
  children: (handleClick: () => void) => React.ReactNode;
}

const PublishPropertyAction = ({
  propertyId,
  propertyName,
  children,
}: IProps) => {
  const [publishProperty, mutationResult] = useMutation(
    PublishPropertyDocument,
    {
      variables: { id: propertyId },
    },
  );
  return (
    <ActionStates
      {...mutationResult}
      action={publishProperty}
      confirmationModal={true}
      confirmationTitle="Publish property"
      confirmationMessage={`Are you sure you want to publish ${propertyName}?`}
      confirmationLabel="Publish"
      loadingMessage="Publishing..."
    >
      {children}
    </ActionStates>
  );
};

export default PublishPropertyAction;
