import { boolean, object, string, InferType } from 'yup';

export const contactFormSchema = object()
  .shape({
    confirmationEmails: boolean().nullable(true),
    email: string().required('Enter an email').email('Enter a valid email'),
    name: string().required('Enter a name'),
    phone: string()
      .required('Enter a phone number')
      .max(25, 'Phone number must be 25 characters or less'),
    position: string(),
    vccInEmail: boolean().nullable(true),
  })
  .noUnknown();

export type FormValues = InferType<typeof contactFormSchema>;
