import { Modal } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

import styled from '@emotion/styled/macro';

export const StyledModal = styled(Modal)`
  .ModalOverlay {
    z-index: ${themeGet('zIndices.overlay')};
  }
` as typeof Modal;

StyledModal.body = Modal.body;
StyledModal.footer = Modal.footer;
StyledModal.header = Modal.header;
