import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

const LazyPropertyGroupDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "property-group-dashboard" */ './PropertyGroupDashboard'
    ),
);

export const PropertyGroupDashboard = () => (
  <Suspense fallback={<GenericLoadingIndicator />}>
    <LazyPropertyGroupDashboard />
  </Suspense>
);
