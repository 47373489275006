import React from 'react';
import { Icon } from '@qga/roo-ui/components';

interface IProps {
  size: number;
}

const Avatar = ({ size }: IProps) => (
  <Icon name="accountCircle" size={size} color="greys.alto" />
);

Avatar.defaultProps = {
  size: 30,
};

export default Avatar;
