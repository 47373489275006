import React, { useMemo } from 'react';
import { Alert, Link } from '@qga/roo-ui/components';
import supportedBrowsersRegexp from 'src/__generated__/supportedBrowsersRegexp';

export const UnsupportedBrowserNotice = () => {
  const isSupportedBrowser = useMemo(() => {
    return supportedBrowsersRegexp.test(navigator.userAgent);
  }, []);

  if (isSupportedBrowser) {
    return null;
  }

  return (
    <Alert variant="error" mb={0} contained={true}>
      You are using an <strong>unsupported</strong> browser. Please{' '}
      <Link href="https://browsehappy.com/">upgrade your browser</Link> to
      improve your experience and security.
    </Alert>
  );
};
