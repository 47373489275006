import React, { useCallback, useMemo } from 'react';
import { ContactType } from 'src/__generated__/graphqlTypes';
import { ContactForm } from 'src/components/ContactForm';
import { PageView } from 'src/components/PageView';
import { ManageContactsDocument } from 'src/graphql/queries/ManageContacts';
import { matchPath, useLocation } from 'react-router';
import { SaveKeyContactDocument } from 'src/graphql/mutations/SaveKeyContact';
import { usePropertyIdAlways } from 'src/hooks/usePropertyId';
import { useQuery, useMutation } from '@apollo/client';
import PageTitle from 'src/primitives/PageTitle';

type ContactFormProps = React.ComponentProps<typeof ContactForm>;

export const KeyContacts: React.FC = () => {
  const { pathname } = useLocation();

  const match = matchPath<{ contactType: string }>(pathname, {
    path: '/properties/:propertyId/contacts/:contactType',
  });

  const propertyId = usePropertyIdAlways();

  const unsafeContactType = match?.params.contactType;

  const contactType = useMemo<ContactType | 'unknown'>(() => {
    const value = unsafeContactType?.toLowerCase();
    switch (value) {
      case 'billing':
        return ContactType.Billing;
      case 'primary':
        return ContactType.Primary;
      case 'reservations':
        return ContactType.Reservation;
      default:
        return 'unknown';
    }
  }, [unsafeContactType]);

  const contactTypeString = useMemo<string>(() => {
    switch (contactType) {
      case ContactType.Billing:
        return 'Billing';
      case ContactType.Primary:
        return 'Primary Contact';
      case ContactType.Reservation:
        return 'Reservations';
      default:
        return '';
    }
  }, [contactType]);

  const { data } = useQuery(ManageContactsDocument, {
    variables: {
      propertyId,
    },
    skip: contactType === 'unknown',
  });

  const property = data?.viewer?.property;

  const contactData = useMemo(() => {
    switch (contactType) {
      case ContactType.Billing:
        return property?.billingContact;
      case ContactType.Primary:
        return property?.primaryContact;
      case ContactType.Reservation:
        return property?.reservationsContact;
      case 'unknown':
        return undefined;
    }
  }, [contactType, property]);

  const initialValues = useMemo<
    ContactFormProps['initialValues'] | undefined
  >(() => {
    if (!contactData) {
      return undefined;
    }
    return {
      ...contactData,
      position: contactData.position ?? '',
      phone: contactData.phone ?? '',
    };
  }, [contactData]);

  const [saveKeyContact] = useMutation(SaveKeyContactDocument);

  const handleSubmit = useCallback<ContactFormProps['onSubmit']>(
    async (values) => {
      if (contactType === 'unknown') {
        return Promise.reject(new Error('Unknown contact type'));
      }

      await saveKeyContact({
        variables: {
          input: {
            propertyId,
            contactType,
            email: values.email,
            name: values.name,
            phone: values.phone,
            position: values.position,
            vccInEmail: values.vccInEmail,
            confirmationEmails: values.confirmationEmails,
          },
        },
      });
    },
    [contactType, propertyId, saveKeyContact],
  );

  return (
    <PageView title={`Key Contacts ${contactTypeString}`}>
      <PageTitle section="Key contacts" title={contactTypeString} />
      <ContactForm
        key={contactType}
        contactType={contactType}
        initialValues={initialValues}
        isReservationsContact={contactType === ContactType.Reservation}
        onSubmit={handleSubmit}
      />
    </PageView>
  );
};
