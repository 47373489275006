import React from 'react';
import { Paragraph, Box, BoxProps } from '@qga/roo-ui/components';

const Note: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    borderLeft={2}
    borderColor="brand.primary"
    pl={3}
    py={1}
    mb={3}
    {...props}
  >
    <Paragraph color="greys.steel" mb={0} lineHeight="tight">
      {children}
    </Paragraph>
  </Box>
);

export default Note;
