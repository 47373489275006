import { keyframes } from '@emotion/core';
import { darken, lighten, rem } from 'polished';
import { Box, BoxProps } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

import styled from '@emotion/styled/macro';

const shimmer = keyframes`
  0% {
    background-position: -${rem('200px')} 0;
  }

  100% {
    background-position: calc(${rem('200px')} + 100%) 0;
  }
`;

const background = (props: BoxProps) =>
  darken(0.025, themeGet('colors.greys.porcelain')(props));
const foreground = (props: BoxProps) =>
  lighten(0.0125, themeGet('colors.greys.porcelain')(props));

const Shimmer = styled(Box)<BoxProps>`
  animation: ${shimmer} 1.2s ease-in-out infinite;
  background-color: ${background};
  background-image: linear-gradient(
    90deg,
    ${background},
    ${foreground},
    ${background}
  );
  background-size: ${rem('200px')} 100%;
  background-repeat: no-repeat;
`;

Shimmer.displayName = 'Shimmer';

Shimmer.defaultProps = {
  borderRadius: 'default',
  height: 16,
  my: 1,
};

export default Shimmer;
