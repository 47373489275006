import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Maybe } from 'src/types';
import { UseProperty_PropertyFragment } from '../useProperty/useProperty_Property';
import useProperty from '../useProperty';
import { HppRedirect } from '../../components/HppRedirect';

type HppRedirectProps = React.ComponentProps<typeof HppRedirect>;
type Redirect = {
  from: string;
  to: string;
  when?: Partial<UseProperty_PropertyFragment>;
  props?: Omit<HppRedirectProps, 'from' | 'to'>;
};

const HPP_REDIRECTS: Redirect[] = [
  {
    from: '/admin',
    to: '/',
  },
  {
    from: '/extranet/supplier_agreement/standard',
    to: '/supplier_agreement/standard',
    props: {
      asset: true,
    },
  },
  {
    from: '/properties/:propertyId/room-types',
    to: '/properties/:propertyId/room_types',
  },
  {
    from: '/properties/:propertyId/reservations',
    to: '/properties/:propertyId/reservations',
  },
  {
    from: '/properties/:propertyId/trails',
    to: '/properties/:propertyId/trails',
  },
  {
    from: '/properties/:propertyId/user',
    to: '/properties/:propertyId/user',
  },
  {
    from: '/properties/:propertyId/rates-and-inventory/:roomTypeId/bulk-update',
    to: '/bulk_inventories/:roomTypeId/edit',
    when: {
      ratesServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/rates-and-inventory/:roomTypeId',
    to: '/inventories/:roomTypeId',
    when: {
      ratesServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/details/policies',
    to: '/properties/:propertyId/edit#policies',
  },
  {
    from: '/properties/:propertyId/details/commercial-terms',
    to: '/properties/:propertyId/edit#commercial_terms',
  },
  {
    from: '/properties/:propertyId/details/images',
    to: '/properties/:propertyId/edit#images',
  },
  {
    from: '/properties/:propertyId/details/other',
    to: '/properties/:propertyId/edit',
  },
  {
    from: '/properties/:propertyId/rate-plans/overview',
    to: '/properties/:propertyId/offers',
    when: {
      ratePlansServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/rate-plans/create',
    to: '/properties/:propertyId/offers/new',
    when: {
      ratePlansServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/rate-plans/:ratePlanId',
    to: '/inventories/:propertyId',
    when: {
      ratePlansServiceEnabled: false,
    },
  },
];

export function useLegacyRedirects() {
  const property = useProperty();
  const matchRoute = useRouteMatch;

  const redirectProps = HPP_REDIRECTS.reduce((memo, config) => {
    const match = matchRoute(config.from);
    if (memo || !match) {
      return memo;
    }
    const conditions =
      property && match && config.when
        ? !Object.entries(config.when).some(([key, value]) => {
            return (
              property[key as keyof UseProperty_PropertyFragment] !== value
            );
          })
        : true;

    if (conditions) {
      return { from: config.from, to: config.to, ...config.props };
    }
    return null;
  }, null as Maybe<HppRedirectProps>);

  if (redirectProps) {
    return React.createElement(HppRedirect, redirectProps);
  }
}
