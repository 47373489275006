import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Icon } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { useQuery } from '@apollo/client';
import { PropertyGroupDocument } from 'src/graphql/queries/PropertyGroup';
import { QueryStates } from 'src/components/QueryStates';

interface IProps {
  propertyGroupId: string | number;
}

const StyledIcon = styled(Icon)`
  background-color: ${themeGet('colors.greys.porcelain')};
  border-radius: ${themeGet('radii.rounded')};

  &:hover {
    color: ${themeGet('colors.ui.linkHover')};
  }
`;

const PropertyGroupLink = ({ propertyGroupId }: IProps) => {
  const { data, loading, error, refetch } = useQuery(PropertyGroupDocument, {
    variables: { propertyGroupId: propertyGroupId.toString() || '' },
  });
  const totalProperties = data?.propertyGroup?.totalProperties || 0;

  return (
    <QueryStates loading={loading} error={error} refetch={refetch}>
      {totalProperties > 1 && (
        <Link to={`/property-groups/${propertyGroupId}`} title="Property group">
          <StyledIcon
            name="chevronLeft"
            color="blueGrey.slateGrey"
            size={36}
            mr={4}
            p={1}
          />
        </Link>
      )}
    </QueryStates>
  );
};

export default PropertyGroupLink;
