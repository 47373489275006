import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Alert,
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Link,
  NakedButton,
  Paragraph,
} from '@qga/roo-ui/components';

import { ContactLink } from 'src/components/ContactLink';
import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout';
import useAuth from 'src/hooks/useAuth';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { useFeatureFlag } from 'src/lib/featureFlags';
import { PasswordResetAlert } from 'src/components/PasswordResetAlert';

const ForgotPassword: React.FC = () => {
  const { resetPassword } = useAuth();
  const location = useLocation<
    | {
        email?: string;
        forcedPasswordReset?: boolean;
        notification?: string;
      }
    | undefined
  >();
  const isExpiredPasswordRedirectOn =
    useFeatureFlag('expired_password_redirect') === 'on';
  const displayPasswordExpiryBanner = useFeatureFlag('password_expiry_banner');
  const displayPasswordEnhancement =
    useFeatureFlag('password_enhancement') === 'on';

  const heading =
    isExpiredPasswordRedirectOn && location.state?.forcedPasswordReset === true
      ? "It's time to reset your password"
      : 'Reset your password';

  const [state, setState] = useState<{ success: boolean; email?: string }>({
    success: false,
  });
  const forgotPassword = useCallback(
    async (email: string) => {
      await resetPassword(email);

      setState({
        email,
        success: true,
      });
    },
    [resetPassword, setState],
  );

  const tryAgain = () => {
    setState({
      email: undefined,
      success: false,
    });
  };

  const descriptionText = useMemo<React.ReactNode>(() => {
    if (isExpiredPasswordRedirectOn) {
      return 'Enter the email associated with your Extranet account to receive a one-time verification code';
    } else if (displayPasswordEnhancement) {
      return "Enter the email associated with your Extranet account. If an account exists, we'll send you a one-time verification code.";
    }
    return "Enter the email associated with your Extranet account and we'll send you an email with instructions to reset your password.";
  }, [displayPasswordEnhancement, isExpiredPasswordRedirectOn]);

  if (state.success && displayPasswordEnhancement) {
    return (
      <Redirect
        to={{ pathname: '/reset-password', state: { email: state.email } }}
      />
    );
  }

  return (
    <UnauthenticatedLayout showBanners={true} title={heading}>
      {displayPasswordExpiryBanner === 'on' && <PasswordResetAlert />}
      <Container maxWidth="wide">
        <Flex justifyContent="center">
          <Box width={[1, 1, 5 / 6]}>
            {location.state?.notification && (
              <Box mt={6}>
                <Alert variant="error">{location.state?.notification}</Alert>
              </Box>
            )}

            <Heading.h2 mt={6}>{heading}</Heading.h2>

            {state.success ? (
              <>
                <Paragraph my={6}>
                  <Icon name="send" mr={2} />
                  An email was sent to {state.email} containing a password reset
                  link.
                </Paragraph>

                <Heading.h4>Didn&apos;t receive the email?</Heading.h4>

                <Paragraph>Check your email junk folder</Paragraph>

                <Paragraph>
                  <NakedButton onClick={tryAgain}>
                    <Link as="span">Resend the email</Link>
                  </NakedButton>
                </Paragraph>

                <Paragraph>
                  <Link as={RouterLink} to="/sign-in">
                    Cancel
                  </Link>{' '}
                  and back to sign in
                </Paragraph>
              </>
            ) : (
              <Flex flexWrap="wrap">
                <Box width={[1, 7 / 10]}>
                  <Paragraph mt={4} mb={6}>
                    {descriptionText}
                  </Paragraph>
                </Box>

                <Box width={[1, 1 / 2]}>
                  <ForgotPasswordForm onForgotPassword={forgotPassword} />
                </Box>

                <Box width={[1, 7 / 10]}>
                  <Paragraph mt={4} mb={6} color="greys.steel">
                    Can't remember your login email? Please{' '}
                    <ContactLink color="greys.steel" underline={true} />.
                  </Paragraph>
                </Box>
              </Flex>
            )}
          </Box>
        </Flex>
      </Container>
    </UnauthenticatedLayout>
  );
};

export default ForgotPassword;
