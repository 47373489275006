import { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';

type InitialValue = string;
type Value = string;

const useDebounceInput = (
  initialValue: InitialValue = '',
  onChange: (value: Value) => void,
  delay = 16,
  synchronousUpdates = process.env.REACT_APP_ENVIRONMENT === 'test',
): [Value, (value: Value) => void] => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const nextValue = initialValue;

    if (value !== nextValue) {
      setValue(nextValue);
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useDebounce(
    () => {
      if (value !== initialValue) onChange(value);
    },
    delay,
    [value],
  );

  useEffect(() => {
    if (synchronousUpdates) onChange(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};

export default useDebounceInput;
