import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';

function strArrReducer(memo: string[], value: unknown): string[] {
  if (typeof value === 'string' && value.length > 0) {
    memo.push(value);
  }
  return memo;
}

function parseObjectsToString(
  formikObject?: Record<string, unknown> | string,
): string | undefined {
  if (typeof formikObject === 'object')
    return Object.values(formikObject).toString();
  else return formikObject;
}

export function useFormikErrors() {
  const formik = useFormikContext<Record<string, unknown>>();
  const submitCount = formik.submitCount;
  const formikErrors = formik.errors;
  const formikStatusErrors = formik.status?.errors;

  return useMemo<string[]>(() => {
    if (!submitCount) {
      return [];
    }

    const errStrings = uniq(
      flattenDeep(Object.values(formikErrors))
        .map(parseObjectsToString)
        .reduce(strArrReducer, []),
    );

    const statusStrings = Array.isArray(formikStatusErrors)
      ? formikStatusErrors.reduce(strArrReducer, [])
      : [];

    return [...errStrings, ...statusStrings];
  }, [submitCount, formikErrors, formikStatusErrors]);
}
