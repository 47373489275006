import React from 'react';
import { Box, Container } from '@qga/roo-ui/components';
import styled from '@emotion/styled/macro';

import Panel from 'src/primitives/Panel';

const Outer = styled(Panel)`
  &:nth-last-of-type(2) {
    flex: 1 1 auto;
  }
`;

type Props = {
  maxWidth?: string;
  panelProps?: React.ComponentProps<typeof Panel>;
};

export const FormSection: React.FC<Props> = ({
  children,
  maxWidth,
  panelProps,
}) => (
  <Outer {...panelProps}>
    <Container>
      <Box maxWidth={maxWidth ?? 'form'} data-testid="form-container">
        {children}
      </Box>
    </Container>
  </Outer>
);
