import useAuth from '../useAuth';
import { useLayoutEffect, useRef } from 'react';
import { AnalyticsUser } from './payloadTypes';

export function useAnalyticsUser() {
  const { user } = useAuth();
  const userRef = useRef<AnalyticsUser>(
    user ? { user_id: user.uuid, user_role: user.role } : null,
  );
  useLayoutEffect(() => {
    if (userRef.current === null && user === null) {
      return;
    } else if (
      user &&
      (userRef.current?.user_id !== user.uuid ||
        userRef.current?.user_role !== user.role)
    ) {
      userRef.current = {
        user_id: user.uuid,
        user_role: user.role,
      };
    } else if (userRef.current && !user) {
      userRef.current = null;
    }
  }, [user, userRef]);

  return userRef;
}
