/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../__generated__/graphqlTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
const usedFragments: string[] = [];
export type ManagePropertyQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type ManagePropertyQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'Viewer';
    id: string;
    property: {
      __typename?: 'Property';
      id: number;
      name: string;
      currencyCode: string | null;
      country: string;
      canManageContent: boolean;
      canManageRates: boolean;
      foreignCurrency: boolean;
      ratesServiceEnabled: boolean;
      ratePlansServiceEnabled: boolean;
      uuid: string;
      inventoryProvider: Types.InventoryProvider;
      integrationPartner: string;
      fx: {
        __typename?: 'FX';
        currencyCode: string;
        exchangeRate: number;
        updatedAt: string;
      } | null;
    } | null;
  } | null;
};

export const ManagePropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'useProperty_Property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeatureFlags_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integrationPartner' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useProperty_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManageContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManageRates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'foreignCurrency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fx' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCode' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exchangeRate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratesServiceEnabled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratePlansServiceEnabled' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FeatureFlags_Property' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagePropertyQuery, ManagePropertyQueryVariables>;
