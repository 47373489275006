import React from 'react';

import { HttpRedirect } from 'src/components/HttpRedirect';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import buildHppUrl, { buildHppAssetUrl } from 'src/lib/buildHppUrl';
import { generatePath, match } from 'react-router';

type Props = {
  to: string;
  asset?: boolean;
  push?: boolean;
  computedMatch?: match;
  from?: string;
  flash?: string;
};

export const HppRedirect = ({
  to,
  asset = false,
  flash,
  computedMatch,
}: Props) => {
  const buildUrl = asset ? buildHppAssetUrl : buildHppUrl;

  return (
    <HttpRedirect
      to={buildUrl(generatePath(to, computedMatch?.params), {
        flashNotice: flash,
      })}
    >
      <LoadingOverlay />
    </HttpRedirect>
  );
};
