import React, { Suspense } from 'react';

import { GenericLoadingIndicator } from '../../components/GenericLoadingIndicator';

function loadPromotions(
  key: 'CreatePromotion' | 'EditPromotion' | 'PromotionIndex',
) {
  return import(/* webpackChunkName: "promotions" */ './asyncBundle').then(
    (module) => ({ default: module[key] }),
  );
}

const LazyCreatePromotion = React.lazy(() => loadPromotions('CreatePromotion'));

export const CreatePromotion = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyCreatePromotion />
    </Suspense>
  );
};

const LazyEditPromotion = React.lazy(() => loadPromotions('EditPromotion'));

export const EditPromotion = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyEditPromotion />
    </Suspense>
  );
};

const LazyPromotionIndex = React.lazy(() => loadPromotions('PromotionIndex'));

export const PromotionIndex = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyPromotionIndex />
    </Suspense>
  );
};
