import React, { ReactNode } from 'react';
import { Box, Icon, NakedButton } from '@qga/roo-ui/components';
import { StyledModal as Modal } from 'src/components/Modal';

type Props = {
  children: ReactNode;
  heading: ReactNode;
  isOpen: boolean;
  overflowVisible?: boolean;
  onClose: () => void;
};

export function ModalWrapper({
  children,
  heading,
  isOpen,
  overflowVisible = false,
  onClose,
  ...props
}: Props) {
  const modalStyle = overflowVisible
    ? { content: { overflow: 'visible' } }
    : {};

  return (
    <Modal isOpen={isOpen} {...props} style={modalStyle}>
      <Modal.body
        textAlign="left"
        style={{
          alignItems: 'flex-start',
          padding: '36px 24px 30px 24px',
        }}
      >
        <NakedButton
          role="button"
          data-testid="close-modal-button"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '14px',
            top: '10px',
          }}
        >
          <Icon name="close" size={24} />
        </NakedButton>

        <Box>{heading}</Box>

        {children}
      </Modal.body>
    </Modal>
  );
}
