import { useFeatureFlag, useIsFeatureFlagLoaded } from '../lib/featureFlags';
import useCurrentUser from './useCurrentUser';

export function useInclusionsFeatureToggle(): [boolean, boolean] {
  const loaded = useIsFeatureFlagLoaded();
  const feature = useFeatureFlag('inclusions_enhancement') === 'on';
  const isStaff = useCurrentUser().isStaff;

  return [loaded && feature && isStaff, !loaded];
}
