import { rem } from 'polished';
import React, { useMemo } from 'react';
import { Box, Icon, Text } from '@qga/roo-ui/components';
import sentenceCase from 'sentence-case';

import { Status } from 'src/__generated__/graphqlTypes';

interface IProps {
  children?: React.ReactNode;
  status: Status;
}

const StatusIndicator = ({ status, children }: IProps) => {
  const iconProps = useMemo(() => {
    switch (status) {
      case Status.Published:
        return { color: 'ui.success', name: 'checkCircle' };
      case Status.Archived:
        return { color: 'greys.steel', name: 'removeCircle' };
      default:
        return { color: 'ui.error', name: 'pauseCircleFilled' };
    }
  }, [status]);

  return (
    <Box
      bg="greys.porcelain"
      py={1}
      px={3}
      borderRadius={rem('100px')}
      data-testid="status-indicator"
    >
      <Icon {...iconProps} size={20} />

      <Text color="blueGrey.slateGrey" px={1}>
        {sentenceCase(status)}
      </Text>

      {children}
    </Box>
  );
};

export default StatusIndicator;
