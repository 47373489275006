import styled from '@emotion/styled/macro';
import { rem } from 'polished';
import { Card, List } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

const BORDER_COLOR = themeGet('colors.greys.alto');

export const PasswordRequirementCard = styled(Card)`
  background-color: ${themeGet('colors.white')};
  border: ${themeGet('borders.2')} ${BORDER_COLOR};
  position: relative;
  border-radius: 0;
  padding: ${themeGet('space.4')} ${themeGet('space.6')};
  width ${rem('350px')};

  &:before {
    background-color: ${themeGet('colors.white')};
    border: ${themeGet('borders.2')} transparent;
    border-top-color: ${BORDER_COLOR};
    border-left-color: ${BORDER_COLOR};

    transform: rotate(-45deg);

    content: '';
    height: ${rem('20px')};
    width: ${rem('20px')};
    position: absolute;
    top: 15%;
    left: ${rem('-10px')};
  }
`;

export const PasswordRequirementList = styled(List)`
  list-style-type: none;
  margin-bottom: 0;
`;
