import React from 'react';
import { Checkbox } from '@qga/roo-ui/components';

import RadioField from 'src/fields/RadioField';

const CheckboxField = (props: React.ComponentProps<typeof RadioField>) => (
  <RadioField {...props} />
);

CheckboxField.defaultProps = {
  hideLabel: false,
  onBlur: () => undefined,
  onChange: () => undefined,
  tabIndex: 0,
  mb: 4,
  checked: (formValue: boolean) => formValue === true,
  component: Checkbox,
  type: 'checkbox',
};

export default CheckboxField;
