import merge from 'lodash/merge';
import { rem, tint } from 'polished';
import { Theme, theme as baseTheme } from '@qga/roo-ui';
import * as icons from './icons';

import styled, { CreateStyled } from '@emotion/styled/macro';

import { IKeyValue } from 'src/types';

export interface ITheme extends Theme {
  colors: IKeyValue<string> & {
    blueGrey: IKeyValue<string>;
    greys: IKeyValue<string>;
    ui: IKeyValue<string>;
    brand: IKeyValue<string>;
  };
  imageSizes: IKeyValue<{
    height: string;
    width: string;
  }>;
  zIndices: IKeyValue<number>;
}

const border = tint(0.5, baseTheme.colors.greys.alto);

export const theme: ITheme = merge(baseTheme, {
  colors: {
    blueGrey: {
      baliHai: '#8EA3B0',
      slateGrey: '#6F8593',
    },
    border,
    brand: {
      gradient: '#FF0000 0%, #BC0000',
    },
    greys: {
      mineShaft: '#222222',
      background: '#F6F6F6',
      gainsboro: '#D9D9D9',
    },
    status: {
      green: '#26A701',
      red: '#BE0000',
      blue: '#7ED2D0',
      orange: '#F5A623',
    },
  },
  borders: {
    lines: '1px solid #D9D9D9',
  },
  gutters: {
    default: [3, 6, 9], // 12px, 24px, 36px
  },
  icons: { ...baseTheme.icons, ...icons },
  imageSizes: {
    thumbnail: {
      height: rem('110px'),
      width: rem('132px'),
    },
  },
  breakpoints: ['40rem', '52rem', '64rem', '76rem'],
  maxWidths: {
    default: 'none',
    form: rem('760px'),
    formWide: rem('1000px'),
    narrow: rem('650px'),
    wide: rem('1160px'),
  },
  shadows: {
    alert: '0 5px 10px rgba(0, 0, 0, 0.25)',
    dropdown: '0 4px 8px rgba(0, 0, 0, 0.25)',
    inset: `inset 1px 1px 1px 1px rgba(0, 0, 0, 0.15)`,
    soft: '0 1px 20px rgba(0, 0, 0, 0.15)',
    text: '0 2px 4px rgba(0, 0, 0, 0.5)',
  },
  textStyles: {
    h2: {
      fontWeight: baseTheme.fontWeights.normal,
    },
  },
  zIndices: {
    alert: 900,
    header: 200,
    nav: 100,
    overlay: 800,
    popover: 300,
  },
  buttons: {
    muted: {
      color: baseTheme.colors.greys.charcoal,
      backgroundColor: baseTheme.colors.greys.porcelain,
    },
  },
  alertStyles: {
    tip: {
      bg: 'white',
      border: `${baseTheme.borders[1]} ${border}`,
    },
  },
});

export default styled as CreateStyled<ITheme>;
