import { Alert, Link, Container, Box, Text } from '@qga/roo-ui/components';
import React from 'react';

type Props = { displayLinkToReset?: boolean };

export const PasswordResetAlert: React.FC<Props> = ({
  displayLinkToReset = false,
}) => (
  <Box bg="ui.infoBackground">
    <Container maxWidth="wide">
      <Alert variant="info" mb={0}>
        <Text display="block" fontWeight="bold">
          Password expiry
        </Text>
        <Text>
          Please Note: Your Qantas Hotels password expires every 90 days. If you
          are having any issues logging in please{' '}
          {displayLinkToReset ? (
            <Link as="a" href="/forgot-password">
              Reset password
            </Link>
          ) : (
            'Reset Password'
          )}
        </Text>
      </Alert>
    </Container>
  </Box>
);
