import {
  ErrorMessage as CustomErrorMessage,
  ErrorMessageProps,
} from '@qga/roo-ui/components';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import React from 'react';
import { useForm } from 'src/hooks/useForm';

const ErrorMessage = ({
  name,
  type,
  label,
  step,
  ...props
}: {
  name: string;
  type: string;
  label?: string;
  step?: string;
} & ErrorMessageProps) => {
  const { onFieldError } = useForm();
  return (
    <FormikErrorMessage name={name}>
      {(message) => {
        onFieldError({
          name: label || name,
          type,
          errorMessage: message,
          step,
        });
        return (
          <CustomErrorMessage
            fontSize="base"
            arrow="top"
            data-testid="error-message"
            {...props}
          >
            {message}
          </CustomErrorMessage>
        );
      }}
    </FormikErrorMessage>
  );
};

export default ErrorMessage;
