import css from '@emotion/css/macro';
import { tint } from 'polished';
import React from 'react';
import { Box, BoxProps } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

import styled from '@emotion/styled/macro';

interface IProps extends BoxProps {
  highlight?: boolean;
  sticky?: boolean;
}

const backgroundColor = (props: IProps) =>
  props.highlight
    ? themeGet('colors.white')(props)
    : tint(0.9, themeGet('colors.greys.alto')(props));

const Panel = styled(({ highlight, sticky, ...rest }) => (
  <Box {...rest} />
))<IProps>`
  background-color: ${backgroundColor};
  border-color: ${themeGet('colors.border')};

  &:first-child {
    border-top: 0;
  }

  ${(props) =>
    props.sticky &&
    css`
      z-index: ${themeGet('zIndices.nav')(props)};
      position: sticky;
    `};
`;

Panel.defaultProps = {
  borderTop: 1,
  py: 6,
  top: 0,
  bottom: 0,
};

export default Panel;
