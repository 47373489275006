import React from 'react';
import { Transition } from 'react-transition-group';
import { useTimeoutFn, useToggle } from 'react-use';

import FixedAlert, {
  EXITING_TIMEOUT,
  IFixedAlertProps,
} from 'src/primitives/FixedAlert';

interface IProps extends IFixedAlertProps {
  timeout?: number;
}

export const FlashAlert = ({ timeout = 5000, ...rest }: IProps) => {
  const [displayAlert, toggleAlert] = useToggle(true);

  useTimeoutFn(() => toggleAlert(false), timeout);

  return (
    <Transition
      in={displayAlert}
      timeout={EXITING_TIMEOUT}
      unmountOnExit={true}
    >
      {(transitionState) => (
        <FixedAlert
          exiting={transitionState === 'exiting'}
          onClose={() => toggleAlert(false)}
          {...rest}
        />
      )}
    </Transition>
  );
};
