import { Box, BoxProps, Flex, FlexProps } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

export const Row = styled(Flex)<FlexProps>``;
Row.displayName = 'Row';
Row.defaultProps = {
  ...Flex.defaultProps,
  flexWrap: 'wrap',
  ml: -2,
  mr: -2,
};

export const Col = styled(Box)<BoxProps>``;
Col.displayName = 'Col';
Col.defaultProps = {
  px: 2,
};
