import { List, ListItem, ListProps } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

const AlphaList = styled(List)<ListProps>`
  list-style-type: none;
  counter-reset: item;

  > ${ListItem} {
    counter-increment: item;

    &:before {
      font-weight: bold;
      content: '(' counter(item, lower-alpha) ') ';
    }
  }
`;

AlphaList.defaultProps = {
  ...List.defaultProps,
  ordered: true,
};

export default AlphaList;
