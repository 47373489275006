import { Alert, ListItem } from '@qga/roo-ui/components';
import { Form as FormikForm, useFormikContext } from 'formik';
import React from 'react';
import ScrollIntoView from 'react-scroll-into-view-if-needed';
import { DelayedAlert } from 'src/components/DelayedAlert';
import { FlashAlert } from 'src/components/FlashAlert';
import PanelGroup from 'src/primitives/PanelGroup';
import { ErrorList } from './ErrorList';
import { useFormikErrors } from './useFormikErrors';
import { useUnloadPrompt } from 'src/hooks/useUnloadPrompt';
import { FormProvider } from 'src/hooks/useForm';

type Props = {
  bg?: string;
  errorMessage?: string;
  successMessage?: string;
  submittingMessage?: string;
  name: string;
  autoComplete?: 'on' | 'off';
};

export const Form: React.FC<Props> = ({
  bg,
  children,
  errorMessage = "Couldn't save changes.",
  successMessage = 'Changes saved successfully.',
  submittingMessage = 'Saving changes...',
  name,
  autoComplete = 'on',
}) => {
  const formik = useFormikContext<Record<string, unknown>>();
  useUnloadPrompt({ when: formik.dirty && !formik.isSubmitting });
  const errorStrings = useFormikErrors();
  return (
    <FormProvider name={name}>
      <FormikForm
        noValidate
        aria-label="form"
        style={{ display: 'flex', height: '100%' }}
        autoComplete={autoComplete}
      >
        {formik?.status?.success && (
          <FlashAlert variant="success">
            <strong>{successMessage}</strong>
          </FlashAlert>
        )}

        {formik.isSubmitting && (
          <DelayedAlert variant="info">
            <strong>{submittingMessage}</strong>
          </DelayedAlert>
        )}

        <PanelGroup bg={bg}>
          {Boolean(errorStrings.length) && (
            <ScrollIntoView>
              <Alert variant="error" contained mb={0} data-testid="form-errors">
                <strong>{errorMessage}</strong>
                <br />
                <ErrorList>
                  {errorStrings.map((errorMessage) => (
                    <ListItem key={errorMessage}>{errorMessage}</ListItem>
                  ))}
                </ErrorList>
              </Alert>
            </ScrollIntoView>
          )}
          {children}
        </PanelGroup>
      </FormikForm>
    </FormProvider>
  );
};
