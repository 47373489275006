import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { createCookie } from 'src/lib/cookieManager';

export const Vip = () => {
  useEffect(() => {
    createCookie('enableAllFeatures', 'true');
  }, []);

  return <Redirect to="/" />;
};
