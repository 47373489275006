/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../__generated__/graphqlTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
const usedFragments: string[] = [];
export type PropertyHeaderItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;

export type PropertyHeaderItemsQuery = {
  __typename?: 'Query';
  property: {
    __typename?: 'Property';
    id: number;
    name: string;
    previousPropertyId: number | null;
    status: Types.Status;
    publishable: {
      __typename?: 'Publishable';
      billingContact: boolean | null;
      images: boolean | null;
      ratePlans: boolean | null;
      propertyLocation: boolean | null;
      propertyOverview: boolean | null;
      propertyPolicies: boolean | null;
      reservationsContact: boolean | null;
      roomTypes: boolean | null;
    };
  };
};

export const PropertyHeaderItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyHeaderItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'property' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertyStatus_Property' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyStatus_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContact' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ratePlans' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyOverview' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyPolicies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservationsContact' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roomTypes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousPropertyId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyHeaderItemsQuery,
  PropertyHeaderItemsQueryVariables
>;
