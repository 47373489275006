import useAuth, { User } from 'src/hooks/useAuth';

const useCurrentUser = (): User => {
  const { user } = useAuth();

  if (!user) {
    throw new Error(
      '#useCurrentUser can only be used in an authenticated context',
    );
  }

  return user;
};

export default useCurrentUser;
