import { subDays } from 'date-fns';
import { CognitoAttributes, User } from './types';
import { UseAuth_UserFragment } from './UseAuth_User';

const MAX_PASSWORD_AGE_IN_DAYS = 90;

const deserializePhone = (phone?: string | null) => {
  return phone?.replace(/^(\+?61)/, '');
};

export function normalizeUser(
  attrs: UseAuth_UserFragment,
  cognitoAttrs: CognitoAttributes,
): User {
  const flags: string[] = JSON.parse(cognitoAttrs['custom:flags']);
  const isFirstLogin = flags.includes('FIRST_LOGIN');
  const passwordChangedCutoff = subDays(new Date(), MAX_PASSWORD_AGE_IN_DAYS);
  const lastPasswordChange = new Date(
    cognitoAttrs['custom:lastPasswordReset'] ?? 0,
  );
  const passwordHasExpired =
    !isFirstLogin && lastPasswordChange <= passwordChangedCutoff;

  return {
    role: attrs.role,
    propertyGroupId: attrs.propertyGroupId ?? undefined,
    firstName: cognitoAttrs.given_name,
    lastName: cognitoAttrs.family_name,
    name: `${cognitoAttrs.given_name} ${cognitoAttrs.family_name}`,
    email: cognitoAttrs.email,
    emailVerified: cognitoAttrs.email_verified,
    position: cognitoAttrs['custom:position'],
    uuid: cognitoAttrs['custom:uuid'],
    isFirstLogin,
    isStaff: attrs.role === 'staff',
    passwordHasExpired,
    phonePrimary: deserializePhone(cognitoAttrs.phone_number),
    phoneSecondary: deserializePhone(cognitoAttrs['custom:phone_number_2']),
  };
}
