import { Link } from '@qga/roo-ui/components';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  id: number;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const PreviousPropertyLink = ({ id, onClick }: IProps) => {
  return (
    <>
      –{' '}
      <Link
        as={RouterLink}
        underline={true}
        to={`/properties/${id}`}
        onClick={onClick}
      >
        Previous property
      </Link>
    </>
  );
};

export default PreviousPropertyLink;
