import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

const LazyPropertyOverview = React.lazy(() =>
  import(/* webpackChunkName: "property-overview" */ './PropertyOverview').then(
    (module) => ({ default: module.PropertyOverview }),
  ),
);

export const PropertyOverview = () => (
  <Suspense fallback={<GenericLoadingIndicator />}>
    <LazyPropertyOverview />
  </Suspense>
);
