import Cookies, { CookieAttributes } from 'js-cookie';

import { config } from 'src/config';
import isDevelopment from 'src/lib/isDevelopment';

export const createCookie = (
  name: string,
  token: string,
  attributes?: CookieAttributes,
) => {
  Cookies.set(name, token, {
    ...attributes,
    domain: !isDevelopment ? config.REACT_APP_COOKIE_DOMAIN : undefined,
    secure: !isDevelopment,
  });
};

export const setupCookies = ({
  idToken,
  accessToken,
  refreshToken,
}: {
  idToken: string;
  accessToken: string;
  refreshToken: string;
}): void => {
  createCookie('idToken', idToken);
  createCookie('accessToken', accessToken);
  createCookie('refreshToken', refreshToken);
};

export const clearCookies = () => {
  const attributes: CookieAttributes = {
    domain: config.REACT_APP_COOKIE_DOMAIN,
  };

  Cookies.remove('idToken', attributes);
  Cookies.remove('accessToken', attributes);
  Cookies.remove('refreshToken', attributes);
};
