import { useCallback, useRef } from 'react';
import { useAnalyticsUser } from './useAnalyticsUser';
import { useTrackEvent } from './useTrackEvent';
import { matchPath } from 'react-router';
import { pascalize } from 'humps';

export function useTrackPageView() {
  const userData = useAnalyticsUser();
  const dispatch = useTrackEvent();
  const entryPage = useRef<string>(
    userData
      ? document.referrer
      : `${window.location.origin}${window.location.pathname}`,
  );
  return useCallback<(pageTitle: string) => void>(
    (pageTitle: string) => {
      const href = `${window.location.origin}${window.location.pathname}`;
      if (href === entryPage.current) {
        // This hook will mount and remount as part of the auth process,
        // causing state/refs to be reinitialised. Checking the entryPage
        // does not match the current href will avoid tracking duplicate
        // page views.
        return;
      }
      dispatch({
        event: 'page_view',
        event_data: {
          action: 'view',
        },
        site: {
          site_id: 'extranet',
          site_vertical: 'travel',
        },
        content: {
          page_location: href,
          page_referrer: document.referrer,
          page_type: pascalize(`${pageTitle}Page`),
          property_id:
            matchPath<{ id: string }>(window.location.pathname, {
              path: '/properties/:id',
            })?.params.id ?? null,
        },
        user: userData.current,
      });
      entryPage.current = href;
    },
    [dispatch, entryPage, userData],
  );
}
