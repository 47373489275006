import { rem } from 'polished';
import React from 'react';
import { Image, ImageProps, Text } from '@qga/roo-ui/components';
import { qantasTail } from '@qga/roo-ui/logos';

import styled from '@emotion/styled/macro';

interface IProps extends ImageProps {
  src?: string;
}

const Logo = styled(Image)<IProps>`
  vertical-align: bottom;
`;

const QantasHotelsLogo = () => (
  <Text fontSize={['lg', 'xl']}>
    <Logo inline={true} width={[rem('40px'), rem('50px')]} />
    Qantas Hotels Extranet
  </Text>
);

Logo.defaultProps = {
  ...Image.defaultProps,
  src: qantasTail,
};

export default QantasHotelsLogo;
