import { tint } from 'polished';
import { Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled/macro';

const PanelGroup = styled(Flex)`
  background-color: ${(props) =>
    props.bg ? props.bg : tint(0.9, themeGet('colors.greys.alto')(props))};
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-collapse: collapse;
`;

export default PanelGroup;
