import styled from '@emotion/styled/macro';
import { rem } from 'polished';
import { Text, TextProps } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

const Badge = styled(Text)<TextProps>`
  border-radius: ${themeGet('radii.default')};
  font-size: ${themeGet('fontSizes.xs')};
  padding: ${rem('3px')} ${rem('5px')};
  text-transform: uppercase;
  font-weight: bold;
  vertical-align: middle;
`;

Badge.displayName = 'Badge';

export default Badge;
