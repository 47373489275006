import React from 'react';
import { Box } from '@qga/roo-ui/components';
import { Col, Row } from 'src/primitives/Grid';
import { contactFormSchema, FormValues } from './formSchema';
import { useFormik, FormikProvider } from 'formik';
import CheckboxField from 'src/fields/CheckboxField';
import { Form } from 'src/components/Form';
import { FormActions } from 'src/components/FormActions';
import { FormSection } from 'src/components/FormSection';
import InputField from 'src/fields/InputField';
import { ContactType } from 'src/__generated__/graphqlTypes';
import { useHandleSubmit } from 'src/lib/form';

type Props = {
  children?: never;
  contactType: ContactType | 'unknown';
  initialValues?: FormValues;
  isReservationsContact?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
};

export const ContactForm: React.FC<Props> = (props) => {
  const formName = 'ContactForm';

  const submitHandler = useHandleSubmit(formName, props.onSubmit);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: props.initialValues || contactFormSchema.cast({}),
    validationSchema: contactFormSchema,
    onSubmit: submitHandler,
  });

  return (
    <FormikProvider value={formik}>
      <Form name={formName}>
        <FormSection>
          <Box className="data-hj-suppress">
            <InputField name="name" label="Name" suppressTracking />

            <InputField name="position" label="Position (optional)" />

            <InputField name="email" label="Email" suppressTracking />

            <Row>
              <Col width={1 / 2}>
                <InputField
                  name="phone"
                  label="Phone number"
                  suppressTracking
                />
              </Col>
            </Row>
          </Box>
          {props.isReservationsContact && (
            <>
              <CheckboxField
                name="confirmationEmails"
                label="I want to receive a booking notification email for each new booking"
              />

              <CheckboxField
                name="vccInEmail"
                label="I want to receive VCC information in the booking notification email"
              />
            </>
          )}
        </FormSection>
        <FormActions />
      </Form>
    </FormikProvider>
  );
};
