import { Redirect } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import React from 'react';

import { ErrorOverlay } from 'src/components/ErrorOverlay';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import useAuth from 'src/hooks/useAuth';
import useRouteQuery from 'src/hooks/useRouteQuery';
import { useAnalytics } from 'src/hooks/useAnalytics';

const ERROR_MESSAGE = "Couldn't verify your email.";

export const VerifyEmail: React.FC = () => {
  const { confirmVerifyEmail } = useAuth();
  const { trackEmailUpdate } = useAnalytics();
  const code = useRouteQuery().get('code');

  const state = useAsyncRetry(async () => {
    if (!code) return;

    await confirmVerifyEmail(code);
    trackEmailUpdate();
  });

  if (!code) {
    return <ErrorOverlay message={ERROR_MESSAGE} />;
  }

  if (state.loading) {
    return <LoadingOverlay />;
  }

  if (state.error) {
    return <ErrorOverlay message={ERROR_MESSAGE} tryAgain={state.retry} />;
  }

  return <Redirect to="/" />;
};
