import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';

type Props = {
  name: string;
  children: React.ReactNode;
};

export type ContextType = {
  onWizardStepClicked: (payload: { title: string }) => void;
  onFieldBlurred: (payload: {
    name: string;
    type: string;
    value?: string | number | boolean;
    step?: string;
  }) => void;
  onFieldError: (payload: {
    name: string;
    type: string;
    errorMessage: string;
    step?: string;
  }) => void;
};

const FormContext = createContext<ContextType>(
  undefined as unknown as ContextType,
);

export function useForm() {
  const { trackFormFieldBlur, trackFormFieldError } = useAnalytics();

  // If hook is used outside a form (eg: sometimes we just need a text input field) track without the global form fields
  const defaultTracking = useMemo<ContextType>(
    () => ({
      onWizardStepClicked: () => undefined,
      onFieldBlurred: ({ name, type, value }) => {
        trackFormFieldBlur({
          fieldName: name,
          fieldType: type,
          fieldValue: value,
        });
      },
      onFieldError: ({ name, type, errorMessage }) => {
        trackFormFieldError({
          fieldName: name,
          fieldType: type,
          errorMessage,
        });
      },
    }),
    [trackFormFieldBlur, trackFormFieldError],
  );

  return useContext(FormContext) || defaultTracking;
}

// Form tracking events need to use these callbacks in order to access the global form context
// Otherwise individual form elements have no context about the greater form they belong to
export const FormProvider: React.FC<Props> = ({ name, children }) => {
  const [isFirstInteraction, setIsFirstInteraction] = useState<Boolean>(true);
  const {
    trackFormFirstInteraction,
    trackFormWizardStep,
    trackFormFieldBlur,
    trackFormFieldError,
  } = useAnalytics();

  const trackFirstInteraction = useCallback(
    ({ name, step }: { name: string; step?: string }) => {
      if (isFirstInteraction) {
        trackFormFirstInteraction({ formName: name, wizardStep: step });
        setIsFirstInteraction(false);
      }
    },
    [isFirstInteraction, trackFormFirstInteraction, setIsFirstInteraction],
  );

  const context = useMemo<ContextType>(
    () => ({
      onWizardStepClicked: ({ title }) => {
        trackFirstInteraction({ name, step: title });
        trackFormWizardStep({ formName: name, wizardStep: title });
      },
      onFieldBlurred: ({ name: fieldName, type: fieldType, value, step }) => {
        trackFirstInteraction({ name, step });
        trackFormFieldBlur({
          formName: name,
          wizardStep: step,
          fieldName,
          fieldType,
          fieldValue: value,
        });
      },
      onFieldError: ({
        name: fieldName,
        type: fieldType,
        errorMessage,
        step,
      }) => {
        trackFormFieldError({
          formName: name,
          wizardStep: step,
          fieldName,
          fieldType,
          errorMessage,
        });
      },
    }),
    [
      trackFormWizardStep,
      trackFormFieldBlur,
      trackFormFieldError,
      trackFirstInteraction,
      name,
    ],
  );

  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
};
