import React from 'react';

import { ErrorPage, IErrorPageProps } from 'src/pages/ErrorPage';
import Overlay from 'src/primitives/Overlay';

export const ErrorOverlay = (props: IErrorPageProps) => (
  <Overlay data-testid="error-overlay">
    <ErrorPage {...props} />
  </Overlay>
);
