import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from '../../components/GenericLoadingIndicator';

function loadRates(key: 'BulkUpdateRates' | 'UpdateRates') {
  return import(
    /* webpackChunkName: "rates-and-inventory" */ './asyncBundle'
  ).then((module) => ({ default: module[key] }));
}

const LazyBulkUpdateRates = React.lazy(() => loadRates('BulkUpdateRates'));

export const BulkUpdateRates = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyBulkUpdateRates />
    </Suspense>
  );
};

const LazyUpdateRates = React.lazy(() => loadRates('UpdateRates'));

export const UpdateRates = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyUpdateRates />
    </Suspense>
  );
};
