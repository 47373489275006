import { Box, Button, Flex, Link } from '@qga/roo-ui/components';
import { Formik } from 'formik';
import { Form } from 'src/components/Form';
import React, { useState, useCallback, MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { object, string, InferType } from 'yup';
import { CognitoError } from 'src/components/CognitoError';
import { RecaptchaError } from 'src/components/RecaptchaError';
import InputField from 'src/fields/InputField';
import PasswordField from 'src/fields/PasswordField';
import useRecaptcha from 'src/hooks/useRecaptcha';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useHandleSubmit } from 'src/lib/form';

interface IProps {
  onSignIn: (username: string, password: string) => Promise<any>;
}

const formSchema = object().shape({
  password: string().ensure().required('Enter your password'),
  username: string()
    .ensure()
    .required('Enter your email')
    .email('Enter a valid email'),
});

type FormValues = InferType<typeof formSchema>;

export const SignInForm = ({ onSignIn }: IProps) => {
  const formName = 'SignInForm';
  const { trackSignIn, trackCtaClick } = useAnalytics();
  const [hasError, setHasError] = useState(false);
  const { validateAction, hasRecaptchaError } = useRecaptcha();

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      trackCtaClick({
        group: 'Content',
        item: 'Login',
        text: event.currentTarget.innerText ?? '',
        type: 'link',
        url: event.currentTarget.href,
      });
    },
    [trackCtaClick],
  );

  const handleSubmit = useHandleSubmit(formName, async (values: FormValues) => {
    const { username, password } = values;
    try {
      const { validateToken } = await validateAction('qh_extranet_login');

      if (validateToken?.success) {
        await onSignIn(username, password);
        trackSignIn();
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  });

  return (
    <Formik<FormValues>
      initialValues={formSchema.cast({})}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          {hasError && <CognitoError />}
          {hasRecaptchaError && <RecaptchaError />}

          <Form name={formName}>
            <InputField
              underline={false}
              label="Sign in - email address"
              type="email"
              name="username"
              autoComplete="username"
              suppressTracking
            />

            <PasswordField underline={false} label="Password" name="password" />

            <Flex alignItems="center" mt={6}>
              <Box width={1 / 2} color="greys.steel">
                <Link
                  as={RouterLink}
                  to="/forgot-password"
                  underline={true}
                  onClick={handleClick}
                >
                  Forgotten your email or password?
                </Link>
              </Box>

              <Box width={1 / 2}>
                <Button
                  variant="primary"
                  block={true}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Loading...' : 'Sign in'}
                </Button>
              </Box>
            </Flex>
          </Form>
        </>
      )}
    </Formik>
  );
};
