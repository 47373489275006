import {
  addMonths,
  getDay,
  Interval,
  isBefore,
  isValid,
  parse,
  startOfDay,
  formatISO,
} from 'date-fns';
import clone from 'lodash/clone';
import intersectionBy from 'lodash/intersectionBy';
import pullAllBy from 'lodash/pullAllBy';
import unionBy from 'lodash/unionBy';

export const ISO_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_TIME_FORMAT = 'HH:mm:ssxxx';
export const DISPLAY_DATE_FORMAT = 'dd-MM-yyyy';
export const DISPLAY_TIME_FORMAT = "HH:mm 'AEST'";
export const READABLE_DATE_FORMAT = 'd MMMM y';

export const today = startOfDay(new Date());

export const eighteenMonthsFromToday = addMonths(today, 18);

export const findByWeekday = (array: Date[], weekday: number): Date[] =>
  array.filter((d) => getDay(d) === weekday);

export const datesIntersection = (a: Date[], b: Date[]): Date[] =>
  intersectionBy(a, b, String);

export const includesDate = (dates: Date[], date: Date): boolean =>
  Boolean(datesIntersection(dates, [date]).length);

export const withoutDates = (a: Date[], b: Date[]): Date[] =>
  pullAllBy(clone(a), b, String);

export const unionDates = (a: Date[], b: Date[]): Date[] =>
  unionBy(a, b, String);

export const addTimeToDate = (date: Date, time: string): Date =>
  parse(time, 'HH:mm', date);

export const isIntervalValid = ({ start, end }: Interval) =>
  isValid(start) && isValid(end) && isBefore(start, end);

export const formatISODate = (date: Date) =>
  formatISO(date, {
    representation: 'date',
  });

export const formatISOTime = (date: Date) =>
  formatISO(date, {
    representation: 'time',
  });
