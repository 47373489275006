import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { usePropertyId } from 'src/hooks/usePropertyId';
import { useTrackEvent } from './useTrackEvent';
import { useAnalyticsUser } from './useAnalyticsUser';
import { useTrackPageView } from './useTrackPageView';
import useProperty from 'src/hooks/useProperty';
import { UseProperty_PropertyFragment } from '../useProperty/useProperty_Property';
import debounce from 'lodash/debounce';

type Property = UseProperty_PropertyFragment;

export type ContextType = {
  trackClick: (payload: {
    group: string;
    item: string;
    text: string;
    type: 'button' | 'checkbox' | 'link';
    outbound?: boolean;
  }) => void;
  trackCtaClick: (payload: {
    group: string;
    item: string;
    text: string;
    type: 'button' | 'link';
    url: string;
  }) => void;
  trackEmailUpdate: () => void;
  trackInclusionCreate: () => void;
  trackInclusionDelete: () => void;
  trackInclusionError: (payload: { actionType: 'CREATE' | 'DELETE' }) => void;
  trackPageView: (title: string) => void;
  trackNavMenuClick: (payload: {
    groupName: string;
    text: string;
    url: string;
  }) => void;
  trackPasswordReset: () => void;
  trackPasswordResetResendEmail: () => void;
  trackPasswordResetUpdate: () => void;
  trackPromotionEdit: (payload: { isReactivated: boolean }) => void;
  trackPromotionSaved: () => void;
  trackPropertyEditFacilities: () => void;
  trackPropertyEditLocation: () => void;
  trackPropertyEditOverview: () => void;
  trackRatePlanCopyToAllRooms: (payload: {
    dataType: 'GUESTS' | 'RATES';
  }) => void;
  trackRatePlanCreateClick: () => void;
  trackRatePlanDeleteClick: () => void;
  trackRatePlanEditClick: (payload: { destination: string }) => void;
  trackRatePlanFiltersClick: () => void;
  trackRatePlanFilters: (payload: {
    selectedRooms?: string[];
    selectedStatuses?: string;
  }) => void;
  trackRatePlanSelectAllRooms: () => void;
  trackRatePlanSort: (payload: { sort: string }) => void;
  trackRatePlanViewSummary: () => void;
  trackRatesUpdate: (payload: {
    actionType: 'BULK' | 'STANDARD';
    roomId: string;
  }) => void;
  trackSignIn: () => void;
  trackSortUpdate: (payload: {
    group: string;
    item: string;
    sort: { for: string; name: string }[];
  }) => void;
  trackTabClick: (payload: {
    group: string;
    item: string;
    text: string;
    index: number;
  }) => void;
  trackFormFirstInteraction: (payload: {
    formName: string;
    wizardStep?: string;
  }) => void;
  trackFormWizardStep: (payload: {
    formName: string;
    wizardStep: string;
  }) => void;
  trackFormFieldBlur: (payload: {
    formName?: string;
    wizardStep?: string;
    fieldName: string;
    fieldType?: string;
    fieldValue?: string | number | boolean;
  }) => void;
  trackFormFieldError: (payload: {
    formName?: string;
    wizardStep?: string;
    fieldName: string;
    fieldType?: string;
    errorMessage: string;
  }) => void;
  trackFormSubmitted: (payload: { formName: string }) => void;
  trackFormError: (payload: { formName: string; error: unknown }) => void;
};

const Context = createContext<ContextType>(undefined as unknown as ContextType);

export function useAnalytics() {
  return useContext(Context);
}

export const AnalyticsProvider: React.FC = (props) => {
  const propertyId = usePropertyId();
  const dispatch = useTrackEvent();
  const userData = useAnalyticsUser();
  const trackPageView = useTrackPageView();

  // The pathname triggers useEffect twice when navigating to a form. This is because the user arrives on the
  // form, then the form wizard appends the step slug to the URL. This triggers a second pathname event firing
  // Also this structure is fine for debouncing a function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const trackPageViewHandler = useCallback(debounce(trackPageView, 300), []);

  const { name: propertyName }: Property = useProperty();

  const context = useMemo<ContextType>(
    () => ({
      trackClick(payload) {
        dispatch({
          event: 'click',
          event_data: {
            action: 'click',
            group_name: payload.group,
            item_name: payload.item,
            item_text: payload.text,
            item_type: payload.type,
            outbound: Boolean(payload.outbound),
          },
        });
      },
      trackCtaClick(payload) {
        const isAbsoluteUrl = /^(?:(\/\/)|(https?:\/\/))/.test(payload.url);
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: payload.group,
            item_name: payload.item,
            item_text: payload.text,
            item_type: payload.type,
            url: isAbsoluteUrl
              ? payload.url
              : `${window.location.origin}${payload.url}`,
          },
        });
      },
      trackEmailUpdate() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Update email',
          eventCategory: 'User',
        });
      },
      trackInclusionCreate() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Inclusion successfully created',
          eventCategory: 'Create inclusion',
          eventLabel: 'Inclusions page',
          propertyId,
        });
      },
      trackInclusionDelete() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Inclusion successfully deleted',
          eventCategory: 'Delete inclusion',
          eventLabel: 'Inclusions page',
          propertyId,
        });
      },
      trackInclusionError({ actionType }) {
        if (actionType === 'CREATE') {
          dispatch({
            event: 'custom.event',
            eventAction: 'Inclusion creation error',
            eventCategory: 'Create inclusion',
            eventLabel: 'Inclusions page',
            propertyId,
          });
        } else if (actionType === 'DELETE') {
          dispatch({
            event: 'custom.event',
            eventAction: 'Inclusion deletion error',
            eventCategory: 'Delete inclusion',
            eventLabel: 'Inclusions page',
            propertyId,
          });
        }
      },
      trackPageView(title) {
        trackPageViewHandler(title);
      },
      trackNavMenuClick({ groupName, text, url }) {
        dispatch({
          event: 'menu_click',
          event_data: {
            action: 'click',
            component_type: 'menu',
            group_name: groupName,
            item_text: text,
            url,
          },
        });
      },
      trackPasswordReset() {
        dispatch({
          event: 'passwordReset',
          user_event_category: 'Password',
          user_event_action: 'Reset password',
          user_event_label: 'Password reset',
        });
      },
      trackPasswordResetResendEmail() {
        dispatch({
          event: 'passwordReset',
          user_event_category: 'Password',
          user_event_action: 'Resend email',
          user_event_label: 'Password reset',
        });
      },
      trackPasswordResetUpdate() {
        dispatch({
          event: 'passwordReset',
          user_event_category: 'Password',
          user_event_action: 'Update password',
          user_event_label: 'Password reset',
        });
      },
      trackPromotionEdit({ isReactivated }) {
        dispatch({
          event: 'custom.event',
          eventAction: 'Edit',
          eventCategory: 'Promotions',
          propertyId,
        });
        if (isReactivated) {
          dispatch({
            event: 'custom.event',
            eventAction: 'Reactivation',
            eventCategory: 'Promotions',
            propertyId,
          });
        }
      },
      trackPromotionSaved() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Create',
          eventCategory: 'Promotions',
          propertyId,
        });
      },
      trackPropertyEditFacilities() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Edit facilities',
          eventCategory: 'Property',
          propertyId,
        });
      },
      trackPropertyEditLocation() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Edit location',
          eventCategory: 'Property',
          propertyId,
        });
      },
      trackPropertyEditOverview() {
        dispatch({
          event: 'custom.event',
          eventAction: 'Edit Overview',
          eventCategory: 'Property',
          propertyId,
        });
      },
      trackRatePlanCopyToAllRooms({ dataType }) {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: `Copy ${dataType.toLowerCase()} to all`,
            item_text: `Copy ${dataType.toLowerCase()} to all room types`,
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatePlanCreateClick() {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: 'Create Rate Plans',
            item_text: 'Create Rate Plans',
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatePlanDeleteClick() {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: 'Delete Rate Plan',
            item_text: 'Delete',
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatePlanEditClick({ destination }) {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: 'View/Edit Rate Plan',
            item_text: 'View/Edit',
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatePlanFiltersClick() {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: 'Filter Rate Plans',
            item_text: 'Filter',
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatePlanFilters({ selectedRooms, selectedStatuses }) {
        if (selectedRooms) {
          dispatch({
            event: 'filter_update',
            event_data: {
              action: 'update',
              component_type: 'filter',
              group_name: 'RatePlanPage',
              item_name: 'Filter by Room Type',
              item_text: 'Room Type',
              item_type: 'checkbox',
              item_value: selectedRooms.toString(),
            },
          });
        }
        if (selectedStatuses) {
          dispatch({
            event: 'filter_update',
            event_data: {
              action: 'update',
              component_type: 'filter',
              group_name: 'RatePlanPage',
              item_name: 'Filter by Status',
              item_text: 'Status',
              item_type: 'checkbox',
              item_value: selectedStatuses.toString(),
            },
          });
        }
      },
      trackRatePlanSelectAllRooms() {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: 'Edit room types',
            item_text: 'Select all room types',
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatePlanSort({ sort }) {
        dispatch({
          event: 'sort_update',
          event_data: {
            action: 'update',
            component_type: 'sort',
            group_name: 'RatePlanPage',
            item_name: 'Sort By',
          },
          sort: [
            {
              for: 'Sort By',
              name: sort,
            },
          ],
        });
      },
      trackRatePlanViewSummary() {
        dispatch({
          event: 'cta_click',
          event_data: {
            action: 'click',
            component_type: 'cta',
            group_name: 'RatePlanPage',
            item_name: 'View Summary',
            item_text: 'View Summary',
            item_type: 'button',
            url: window.location.href,
          },
        });
      },
      trackRatesUpdate({ roomId }) {
        dispatch({
          event: 'custom.event',
          eventAction: 'Bulk update rates',
          eventCategory: 'Property',
          propertyId,
          roomTypeId: roomId,
        });
      },
      trackSignIn() {
        dispatch({
          event: 'login',
          event_data: {
            action: 'login',
          },
          user: userData.current,
        });
      },
      trackSortUpdate(payload) {
        dispatch({
          event: 'sort_update',
          event_data: {
            action: 'update',
            component_type: 'sort',
            group_name: payload.group,
            item_name: payload.item,
          },
          sort: payload.sort,
        });
      },
      trackTabClick(payload) {
        dispatch({
          event: 'tab_click',
          event_data: {
            action: 'click',
            component_type: 'tab',
            group_name: payload.group,
            index: payload.index,
            item_name: payload.item,
            item_text: payload.text,
          },
        });
      },
      trackFormFirstInteraction({ formName, wizardStep }) {
        dispatch({
          event: 'form_start',
          event_data: {
            component_type: 'form',
            group_name: propertyName,
            form: { form_name: formName },
            action: 'start',
            step: wizardStep ? { step_label: wizardStep } : undefined,
          },
        });
      },
      trackFormWizardStep({ formName, wizardStep }) {
        dispatch({
          event: 'form_step',
          event_data: {
            component_type: 'form',
            group_name: propertyName,
            form: { form_name: formName },
            action: 'step_view',
            step: { step_label: wizardStep },
          },
        });
      },
      trackFormFieldBlur({
        formName,
        wizardStep,
        fieldName,
        fieldType,
        fieldValue,
      }) {
        dispatch({
          event: 'form_exit_field',
          event_data: {
            component_type: 'form',
            group_name: propertyName,
            form: { form_name: formName },
            action: 'exit_field',
            item_name: fieldName,
            item_type: fieldType,
            item_value: fieldValue,
            step: wizardStep ? { step_label: wizardStep } : undefined,
          },
        });
      },
      trackFormFieldError({
        formName,
        wizardStep,
        fieldName,
        fieldType,
        errorMessage,
      }) {
        dispatch({
          event: 'form_error_field',
          event_data: {
            component_type: 'form',
            group_name: propertyName,
            form: { form_name: formName },
            action: 'error_field',
            item_name: fieldName,
            item_type: fieldType,
            errors: [
              {
                id: 'validation_error',
                message: errorMessage,
                name: 'Validation error',
              },
            ],
            step: wizardStep ? { step_label: wizardStep } : undefined,
          },
        });
      },
      trackFormSubmitted({ formName }) {
        dispatch({
          event: 'form_complete',
          event_data: {
            component_type: 'form',
            group_name: propertyName,
            form: { form_name: formName },
            action: 'complete',
          },
        });
      },
      trackFormError({ formName, error }) {
        dispatch({
          event: 'form_error',
          event_data: {
            component_type: 'form',
            group_name: propertyName,
            form: { form_name: formName },
            action: 'error',
            errors: [
              {
                id: 'Error',
                message:
                  error instanceof Error
                    ? error.message
                    : 'The form failed to submit',
                name: error instanceof Error ? error.name : 'Error',
              },
            ],
          },
        });
      },
    }),
    [dispatch, propertyId, trackPageViewHandler, userData, propertyName],
  );

  return <Context.Provider value={context}>{props.children}</Context.Provider>;
};
