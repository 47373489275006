import React from 'react';
import { Toggle } from 'react-powerplug';

import { ConfirmationModal } from 'src/components/ConfirmationModal';
import { FlashAlert } from 'src/components/FlashAlert';

type Children = (handleClick: () => void) => React.ReactNode;

type Props = {
  action: () => Promise<any>;
  children: Children;
  confirmationLabel: string;
  confirmationMessage: string;
  confirmationModal: boolean;
  confirmationTitle: string;
  data?: any;
  error?: Error | boolean;
  errorMessage?: React.ReactNode;
  loading?: boolean;
  loadingMessage?: string;
  successMessage?: React.ReactNode;
};

export type ActionProps = {
  children: Children;
};

export const ActionStates = ({
  action,
  children,
  confirmationLabel,
  confirmationMessage,
  confirmationModal,
  confirmationTitle,
  data,
  error,
  errorMessage,
  loading,
  loadingMessage,
  successMessage,
}: Props) => (
  <>
    {error && !loading && (
      <FlashAlert variant="error" data-testid="flash-alert">
        {errorMessage}
      </FlashAlert>
    )}

    {successMessage && data && !error && (
      <FlashAlert variant="success" data-testid="flash-alert">
        {successMessage}
      </FlashAlert>
    )}

    {!confirmationModal && loadingMessage && loading && (
      <FlashAlert variant="info" data-testid="flash-alert">
        {loadingMessage}
      </FlashAlert>
    )}

    {confirmationModal ? (
      <Toggle>
        {({ on, toggle }) => (
          <>
            <ConfirmationModal
              action={action}
              isOpen={on}
              label={confirmationLabel}
              loading={loading}
              loadingMessage={loadingMessage}
              message={confirmationMessage}
              onRequestClose={toggle}
              title={confirmationTitle}
            />

            {children(toggle)}
          </>
        )}
      </Toggle>
    ) : (
      children(action)
    )}
  </>
);

ActionStates.defaultProps = {
  confirmationLabel: 'Confirm',
  confirmationMessage: 'Are you sure you want to continue?',
  confirmationModal: false,
  confirmationTitle: 'Confirmation',
  errorMessage: 'Sorry, something went wrong. Please try again later.',
};
