import { ApolloClient, ApolloLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Amplify from '@aws-amplify/auth';
import { createApolloCache } from './memoryCache';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { config } from 'src/config';
import { v1 as uuid } from 'uuid';

/*
 * Only apply the x-correlation-id to the outgoing request.
 * If a unique correlation id is generated for each graphql operation,
 * the requests will not be batched as they have different header values.
 */
const fetchWithCorrelationId: typeof fetch = (input, init) => {
  return fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      'x-correlation-id': uuid(),
    },
  });
};

const authLink = setContext(async (_, { headers }) => {
  const idToken = await Amplify.currentSession()
    .then((session) => {
      return session.getIdToken().getJwtToken();
    })
    .catch(() => {
      return undefined;
    });

  return {
    // just authorization header, no need for cookies
    headers: {
      ...headers,
      credentials: process.env.NODE_ENV == 'development' ? 'include' : 'omit',
      authorization: `Bearer ${idToken}`,
      'x-content-type-options': 'nosniff',
    },
  };
});

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  console.warn(`Operation "${operation.operationName}" resolved with errors`);
  console.info(
    `Correlation ID: ${operation.getContext().headers['x-correlation-id']}`,
  );
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: createApolloCache(),
  link: ApolloLink.from([
    errorLink,
    authLink,
    ApolloLink.split(
      (operation) => {
        // decide to use either a file upload link (return true) or batch http link (return false).
        // This check could also be implemented by setting options.context when performing a mutation,
        // and checking for the context value.
        return 'file' in operation.variables;
      },
      // Use upload link when uploading files
      createUploadLink({
        uri: config.REACT_APP_EXTRANET_GRAPHQL_URI,
        fetch: fetchWithCorrelationId,
      }),
      // otherwise, use batch http link for all other requests
      new BatchHttpLink({
        uri: config.REACT_APP_EXTRANET_GRAPHQL_URI,
        batchMax: 5,
        batchInterval: 20,
        fetch: fetchWithCorrelationId,
      }),
    ),
  ]),
});

export default client;
