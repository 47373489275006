import React from 'react';
import { List, ListItem, ListProps } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

interface IProps extends ListProps {
  prefix: string;
}

const DecimalList = styled(({ prefix, ...rest }) => <List {...rest} />)<IProps>`
  list-style-type: none;
  counter-reset: item;

  > ${ListItem} {
    counter-increment: item;

    &:before {
      font-weight: bold;
      content: '${(props) => props.prefix}.' counter(item) '. ';
    }
  }
`;

DecimalList.defaultProps = {
  flush: true,
  ordered: true,
};

export default DecimalList;
