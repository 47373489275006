import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { PropertyGroupPageDocument } from 'src/graphql/queries/PropertyGroupPage';
import { HppRedirect } from 'src/components/HppRedirect';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import useAuth from 'src/hooks/useAuth';

export const LoginRedirector: React.FC = () => {
  const { clearFirstLogin, user } = useAuth();
  const [fetchPropertyGroup, { called, data, loading, error }] = useLazyQuery(
    PropertyGroupPageDocument,
  );
  const propertyGroup = data?.propertyGroup;

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.isFirstLogin) {
      clearFirstLogin();
    }
    const propertyGroupId = user.propertyGroupId?.toString();
    if (propertyGroupId) {
      fetchPropertyGroup({ variables: { propertyGroupId } });
    }
  }, [clearFirstLogin, fetchPropertyGroup, user]);

  if (!user) {
    return null;
  }

  if (user.isFirstLogin) {
    return <Redirect to="/user-details" />;
  }

  if (!user.propertyGroupId) {
    return <HppRedirect to="/" />;
  }

  if (called && loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <HppRedirect to="/" />;
  }

  if (propertyGroup) {
    if (propertyGroup.properties.length === 1) {
      return <Redirect to={`/properties/${propertyGroup.properties[0].id}`} />;
    } else {
      return <Redirect to={`/property-groups/${propertyGroup.id}`} />;
    }
  }

  return null;
};
