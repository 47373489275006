import filter from 'lodash/filter';
import React, { MouseEventHandler, useCallback } from 'react';
import { Icon } from '@qga/roo-ui/components';
import { Link } from 'react-router-dom';
import { Status } from 'src/__generated__/graphqlTypes';
import { Dropdown } from 'src/components/Dropdown';
import { PropertyStatus_PropertyFragment } from './PropertyStatus_Property';
import { useAnalytics } from 'src/hooks/useAnalytics';
import OnboardingLink from '../OnboardingLink';
import PropertyStatusActions from '../PropertyStatusActions';
import PreviousPropertyLink from '../PreviousPropertyLink';
import StatusIndicator from '../StatusIndicator';
import useCurrentUser from 'src/hooks/useCurrentUser';

type Props = {
  property: PropertyStatus_PropertyFragment;
};

export const PropertyStatus: React.FC<Props> = ({ property }) => {
  const { trackNavMenuClick } = useAnalytics();

  const handleMenuClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      trackNavMenuClick({
        groupName: 'Property Status Nav',
        text: event.currentTarget.innerText,
        url: event.currentTarget.href || window.location.href,
      });
    },
    [trackNavMenuClick],
  );

  const user = useCurrentUser();
  const isArchived = property.status === Status.Archived;
  const isInvited = property.status === Status.Invited;
  const isOnboarding = property.status === Status.Onboarding;
  const isProspective = property.status === Status.Prospective;
  const isPublished = property.status === Status.Published;
  const isUnpublished = property.status === Status.Unpublished;

  const stepsRemaining = filter(
    property.publishable,
    (publishableItem) => !publishableItem,
  ).length;

  const canPublish = (isOnboarding || isUnpublished) && !stepsRemaining;
  const canDelete = !isPublished && !isUnpublished;

  const showDropdown = !isArchived && user?.isStaff;

  const indicator = (
    <StatusIndicator status={property.status}>
      {showDropdown && <Icon name="keyboardArrowDown" />}

      {isOnboarding && (
        <OnboardingLink
          propertyId={property.id}
          stepsRemaining={stepsRemaining}
          onClick={handleMenuClick}
        />
      )}

      {property.previousPropertyId && user?.isStaff && (
        <PreviousPropertyLink
          id={property.previousPropertyId}
          onClick={handleMenuClick}
        />
      )}
    </StatusIndicator>
  );

  if (!showDropdown) {
    return indicator;
  }

  const manageUserUrl = `/properties/${property.id}/user`;

  return (
    <PropertyStatusActions
      propertyId={property.id}
      propertyName={property.name}
    >
      {({
        publishProperty,
        unpublishProperty,
        deleteProperty,
        archiveProperty,
      }) => (
        <Dropdown>
          {indicator}

          {canPublish && (
            <Dropdown.item
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                publishProperty();
                handleMenuClick(e);
              }}
            >
              Publish
            </Dropdown.item>
          )}

          {isProspective && (
            <Dropdown.item
              as={Link}
              to={manageUserUrl}
              onClick={handleMenuClick}
            >
              Invite user
            </Dropdown.item>
          )}

          {isInvited && (
            <Dropdown.item
              as={Link}
              to={manageUserUrl}
              onClick={handleMenuClick}
            >
              Manage user
            </Dropdown.item>
          )}

          {isPublished && (
            <Dropdown.item
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                unpublishProperty();
                handleMenuClick(e);
              }}
            >
              Unpublish
            </Dropdown.item>
          )}

          {canDelete && (
            <Dropdown.item
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                deleteProperty();
                handleMenuClick(e);
              }}
            >
              Delete
            </Dropdown.item>
          )}

          {isUnpublished && (
            <Dropdown.item
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                archiveProperty();
                handleMenuClick(e);
              }}
            >
              Archive
            </Dropdown.item>
          )}
        </Dropdown>
      )}
    </PropertyStatusActions>
  );
};
