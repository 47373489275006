import React from 'react';
import { Alert } from '@qga/roo-ui/components';

export const RecaptchaError = () => (
  <Alert variant="error" data-testid="recaptcha-error">
    <strong>Anti-spam verification failed</strong>
    <br />
    There was an error verifying reCAPTCHA. Please try signing in again.
  </Alert>
);
