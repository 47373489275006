import React from 'react';
import { Alert } from '@qga/roo-ui/components';

export const CognitoError = () => (
  <Alert variant="error" data-testid="error">
    <strong>Invalid email or password</strong>
    <br />
    Please check your credentials and try again.
  </Alert>
);
