import React from 'react';
import { Alert, Link } from '@qga/roo-ui/components';
import { Link as RouterLink } from 'react-router-dom';

export const OtpCodeError = () => (
  <Alert variant="error" data-testid="verification-code-error">
    <strong>Invalid or expired code</strong>
    <br />
    The code you entered is invalid or has expired. Check the code and try
    again, or{' '}
    <Link as={RouterLink} to="/forgot-password" underline={true}>
      resend a new code
    </Link>
    .
  </Alert>
);
