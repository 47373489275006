import { rem } from 'polished';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ExternalLink,
  Hide,
  Image,
  OutlineButton,
} from '@qga/roo-ui/components';
import { jetstar, qantas } from '@qga/roo-ui/logos';
import useAuth from 'src/hooks/useAuth';

export const HeaderActions = () => {
  const location = useLocation();
  const { user } = useAuth();
  const showLogos = user || location.pathname === '/sign-in';

  return showLogos ? (
    <Hide xs={true} sm={true}>
      <ExternalLink
        href="https://www.qantas.com/hotels"
        data-testid="qantas-cta"
      >
        <Image
          inline={true}
          src={qantas}
          width={rem('155px')}
          height={rem('30px')}
        />
      </ExternalLink>

      <ExternalLink
        href="https://www.jetstar.com/hotels"
        data-testid="jetstar-cta"
      >
        <Image
          inline={true}
          src={jetstar}
          width={rem('112px')}
          height={rem('30px')}
          ml={4}
        />
      </ExternalLink>
    </Hide>
  ) : (
    <OutlineButton as={Link} to="/" variant="primary">
      Sign in
    </OutlineButton>
  );
};
