import React, { Suspense } from 'react';

import { GenericLoadingIndicator } from '../../components/GenericLoadingIndicator';

function loadMediaLibrary(
  key:
    | 'AllImages'
    | 'EditImages'
    | 'PropertyImages'
    | 'RoomTypesImages'
    | 'UploadImages',
) {
  return import(/* webpackChunkName: "media-library" */ './asyncBundle').then(
    (module) => ({ default: module[key] }),
  );
}

const LazyAllImages = React.lazy(() => loadMediaLibrary('AllImages'));

export const AllImages = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyAllImages />
    </Suspense>
  );
};

const LazyEditImages = React.lazy(() => loadMediaLibrary('EditImages'));

export const EditImages = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyEditImages />
    </Suspense>
  );
};

const LazyPropertyImages = React.lazy(() => loadMediaLibrary('PropertyImages'));

export const PropertyImages = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyPropertyImages />
    </Suspense>
  );
};

const LazyRoomTypesImages = React.lazy(() =>
  loadMediaLibrary('RoomTypesImages'),
);

export const RoomTypesImages = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyRoomTypesImages />
    </Suspense>
  );
};

const LazyUploadImages = React.lazy(() => loadMediaLibrary('UploadImages'));

export const UploadImages = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyUploadImages />
    </Suspense>
  );
};
