import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from '../../components/GenericLoadingIndicator';

const LazyPropertyDashboard = React.lazy(() => import('./PropertyDashboard'));

export const PropertyDashboard = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyPropertyDashboard />
    </Suspense>
  );
};
