import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAsync } from 'react-use';

import useAuth from 'src/hooks/useAuth';

export const SignOut: React.FC = () => {
  const { signOut } = useAuth();

  const state = useAsync(async () => {
    await signOut();
  }, []);

  if (state.loading) {
    return null;
  }

  return <Redirect to="/sign-in" />;
};
