import React from 'react';
import { adopt } from 'react-adopt';

import ArchiveProperty from 'src/actions/ArchiveProperty';
import DeleteProperty from 'src/actions/DeleteProperty';
import PublishProperty from 'src/actions/PublishProperty';
import UnpublishProperty from 'src/actions/UnpublishProperty';

interface IRenderProps {
  deleteProperty: () => void;
  publishProperty: () => void;
  unpublishProperty: () => void;
  archiveProperty: () => void;
}

interface IProps {
  propertyId: number;
  propertyName: string;
}

const PropertyStatusActions = adopt<IRenderProps, IProps>({
  archiveProperty: ({ propertyId, propertyName, render }) => (
    <ArchiveProperty propertyId={propertyId} propertyName={propertyName}>
      {render!}
    </ArchiveProperty>
  ),
  deleteProperty: ({ propertyId, propertyName, render }) => (
    <DeleteProperty propertyId={propertyId} propertyName={propertyName}>
      {render!}
    </DeleteProperty>
  ),
  publishProperty: ({ propertyId, propertyName, render }) => (
    <PublishProperty propertyId={propertyId} propertyName={propertyName}>
      {render!}
    </PublishProperty>
  ),
  unpublishProperty: ({ propertyId, propertyName, render }) => (
    <UnpublishProperty propertyId={propertyId} propertyName={propertyName}>
      {render!}
    </UnpublishProperty>
  ),
});

export default PropertyStatusActions;
