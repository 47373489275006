import { useMemo, useReducer } from 'react';
import { usePropertyId } from 'src/hooks/usePropertyId';

const RATE_PLANS_DISMISS_SUMMARY_STORE_KEY =
  'ratePlan:isSummaryLinkTooltipDismissed';

export type PropertyState = {
  ratesExpanded: Record<string, boolean>;
};

export type State = {
  global: {
    ratePlansDismissSummary: boolean;
  };
  property: Record<string, PropertyState>;
};

type Action =
  | { type: 'DISMISS_RATE_PLANS_SUMMARY' }
  | { type: 'TOGGLE_RATES_EXPANDED'; id: string; propertyId: string };

function reducer(state: State, action: Action): State {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'DISMISS_RATE_PLANS_SUMMARY': {
      return {
        ...state,
        global: {
          ...state.global,
          ratePlansDismissSummary: true,
        },
      };
    }
    case 'TOGGLE_RATES_EXPANDED': {
      const propertyState =
        state.property[action.propertyId] ?? DEFAULT_INITIAL_PROPERTY_STATE;
      const nextPropertyState: PropertyState = {
        ...propertyState,
        ratesExpanded: {
          ...propertyState.ratesExpanded,
          [action.id]: !propertyState.ratesExpanded[action.id],
        },
      };
      return {
        ...state,
        property: {
          [action.propertyId]: nextPropertyState,
        },
      };
    }
    default: {
      return state;
    }
  }
}

const DEFAULT_INITIAL_STATE: State = {
  global: {
    ratePlansDismissSummary: (() => {
      try {
        return (
          localStorage.getItem(RATE_PLANS_DISMISS_SUMMARY_STORE_KEY) === 'true'
        );
      } catch {
        return false;
      }
    })(),
  },
  property: {},
};
const DEFAULT_INITIAL_PROPERTY_STATE: PropertyState = {
  ratesExpanded: {},
};

type ActionCreators = {
  dismissRatePlansViewSummary: () => void;
  toggleRatesAndInventory: (id: string) => void;
};

export function useViewStateManager() {
  const [state, dispatch] = useReducer(reducer, DEFAULT_INITIAL_STATE);

  const maybePropertyId = usePropertyId();
  const contextState = useMemo(() => {
    const propertyState = maybePropertyId
      ? state.property[maybePropertyId] ?? DEFAULT_INITIAL_PROPERTY_STATE
      : DEFAULT_INITIAL_PROPERTY_STATE;
    return { ...state.global, ...propertyState };
  }, [state, maybePropertyId]);

  const actionCreators = useMemo<ActionCreators>(() => {
    return {
      dismissRatePlansViewSummary() {
        try {
          localStorage.setItem(RATE_PLANS_DISMISS_SUMMARY_STORE_KEY, 'true');
        } finally {
          dispatch({ type: 'DISMISS_RATE_PLANS_SUMMARY' });
        }
      },
      toggleRatesAndInventory(id) {
        if (!maybePropertyId) {
          throw new Error('no property id');
        }
        dispatch({
          type: 'TOGGLE_RATES_EXPANDED',
          id,
          propertyId: maybePropertyId,
        });
      },
    };
  }, [dispatch, maybePropertyId]);

  return {
    state: contextState,
    actions: actionCreators,
  };
}
