import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import possibleTypes from './possibleTypes.json';
import { Publishable } from '../../__generated__/graphqlTypes';
type EdgeRef = { node: { __ref: string } };
type ConnectionRefs = { edges: EdgeRef[] };

export function createApolloCache() {
  return new InMemoryCache({
    addTypename: true,
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          // returning an array instead of a connection prevents automatic caching
          // - instruct apollo to replace rather than merge new results from Query.images
          images: {
            merge(existing, incoming) {
              return incoming;
            },
          },

          viewer: {
            merge(existing, incoming) {
              if (existing?.id === incoming?.id) {
                return {
                  ...existing,
                  ...incoming,
                };
              }
              return incoming;
            },
          },
        },
      },
      Property: {
        fields: {
          publishable: {
            merge(
              existing: undefined | Partial<Publishable>,
              incoming: Partial<Publishable>,
            ) {
              return {
                ...existing,
                ...incoming,
              };
            },
          },
          ratePlans: {
            keyArgs(args) {
              const {
                first: _,
                after: __,
                ...valuesForKey
              } = args?.input ?? {};
              return JSON.stringify(valuesForKey);
            },
            merge(
              existing: undefined | ConnectionRefs,
              incoming: ConnectionRefs,
            ) {
              const current = existing?.edges ?? [];

              const mergedEdges = current
                .concat(incoming.edges)
                .reduce((memo, edge) => {
                  const updated = incoming.edges.find(
                    (incomingEdge) =>
                      incomingEdge.node.__ref === edge.node.__ref,
                  );
                  if (!updated) {
                    memo.push(edge);
                  }

                  if (updated) {
                    const isDuplicate = memo.some(
                      (value) => value.node.__ref === edge.node.__ref,
                    );
                    if (!isDuplicate) {
                      memo.push(updated);
                    }
                  }
                  return memo;
                }, [] as EdgeRef[]);

              return {
                ...existing,
                ...incoming,
                edges: mergedEdges,
              };
            },
          },
        },
      },
    },
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'RatePlan':
        case 'OpaqueRatePlan':
        case 'LuxuryRatePlan': {
          return `RatePlan:${responseObject.id}`;
        }
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });
}
