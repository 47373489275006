/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../__generated__/graphqlTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
const usedFragments: string[] = [];
export type SaveKeyContactMutationVariables = Types.Exact<{
  input: Types.SaveKeyContactInput;
}>;

export type SaveKeyContactMutation = {
  __typename?: 'Mutation';
  saveKeyContact: {
    __typename?: 'Property';
    id: number;
    publishable: {
      __typename?: 'Publishable';
      billingContact: boolean | null;
      reservationsContact: boolean | null;
    };
    billingContact: {
      __typename?: 'Contact';
      id: number;
      name: string;
      position: string | null;
      email: string;
      phone: string | null;
      confirmationEmails: boolean | null;
      vccInEmail: boolean | null;
    } | null;
    primaryContact: {
      __typename?: 'Contact';
      id: number;
      name: string;
      position: string | null;
      email: string;
      phone: string | null;
      confirmationEmails: boolean | null;
      vccInEmail: boolean | null;
    } | null;
    reservationsContact: {
      __typename?: 'Contact';
      id: number;
      name: string;
      position: string | null;
      email: string;
      phone: string | null;
      confirmationEmails: boolean | null;
      vccInEmail: boolean | null;
    } | null;
  };
};

export const SaveKeyContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveKeyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveKeyContactInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveKeyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingContact' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reservationsContact' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Property' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmationEmails' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vccInEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservationsContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveKeyContactMutation,
  SaveKeyContactMutationVariables
>;
