import * as Sentry from '@sentry/react';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { SplitFactory } from '@splitsoftware/splitio';
import SplitIO from '@splitsoftware/splitio/types/splitio';
import { useFragment } from '@apollo/client';
import Cookies from 'js-cookie';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { config } from 'src/config';
import { usePropertyId } from 'src/hooks/usePropertyId';
import { FeatureFlags_PropertyFragmentDoc as fragment } from './FeatureFlags_Property';

export type FeatureFlag =
  | 'expired_password_redirect'
  | 'global_inclusions'
  | 'inclusions_enhancement'
  | 'password_enhancement'
  | 'password_expiry_banner'
  | 'rate_plans_index_redirect'
  | 'rate_plans_index';

type TypedTreatments = Record<FeatureFlag, SplitIO.Treatment>;

type TreatmentsContext = { isLoaded: boolean; treatments: TypedTreatments };

const defaultTreatments: TypedTreatments = {
  expired_password_redirect: 'control',
  global_inclusions: 'control',
  inclusions_enhancement: 'control',
  password_enhancement: 'control',
  password_expiry_banner: 'control',
  rate_plans_index_redirect: 'control',
  rate_plans_index: 'control',
};

const Context = createContext<TreatmentsContext>(
  {} as unknown as TreatmentsContext,
);

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const [context, setContext] = useState<TreatmentsContext>({
    isLoaded: false,
    treatments: defaultTreatments,
  });
  const propertyId = usePropertyId();
  const enableAllFeatures = Cookies.get('enableAllFeatures') === 'true';

  const { data } = useFragment({
    fragment,
    from: {
      __ref: `Property:${propertyId}`,
    },
  });

  const inventoryProvider = data?.inventoryProvider;
  const integrationPartner = data?.integrationPartner;

  const attributes: SplitIO.Attributes = useMemo(() => {
    const newAttr = {
      inventory_provider_code: inventoryProvider, // Derbysoft, expedia, local, etc
      integration_partner: integrationPartner, // More granular: self-managed, RMS, etc
      property_id: propertyId,
      current_date: new Date().getTime(),
      enable_all_features: enableAllFeatures,
    };
    return omitBy(newAttr, isNil) as SplitIO.Attributes;
  }, [inventoryProvider, propertyId, integrationPartner, enableAllFeatures]);

  useEffect(() => {
    if (!attributes) return;

    const factory: SplitIO.ISDK = SplitFactory({
      core: {
        authorizationKey: config.REACT_APP_SPLIT_AUTHORIZATION_KEY,
        key: 'user_id',
      },
    });

    if (!factory) return;

    const client: SplitIO.IClient = factory.client();

    client.on(client.Event.SDK_READY, () => {
      const featureFlagNames = Object.keys(defaultTreatments);
      const treatments = client.getTreatments(
        featureFlagNames,
        attributes,
      ) as TypedTreatments;

      Object.entries(treatments).forEach(([feature, value]) => {
        Sentry.setTag(`feature:${feature}`, value);
      });

      setContext({
        isLoaded: true,
        treatments: {
          ...defaultTreatments,
          ...treatments,
        },
      });
    });

    return () => {
      client.destroy();
    };
  }, [attributes, setContext]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useFeatureFlag = (feature: FeatureFlag) => {
  const { treatments } = useContext(Context);
  return treatments[feature];
};

export const useIsFeatureFlagLoaded = () => {
  const { isLoaded } = useContext(Context);
  return isLoaded;
};
