import React from 'react';
import { Alert, Container, Link, NakedButton } from '@qga/roo-ui/components';
import { ContactLink } from 'src/components/ContactLink';

type Props = {
  tryAgain?: () => Promise<unknown>;
} & Omit<React.ComponentProps<typeof Container>, 'children'>;

export const GENERIC_ERROR_MESSAGE = 'Sorry, something went wrong.';

export const GenericError = ({ tryAgain, ...props }: Props) => {
  return (
    <Container my={6} {...props}>
      <Alert variant="error">
        <strong>{GENERIC_ERROR_MESSAGE}&nbsp;</strong>
        <br />
        Please{' '}
        {tryAgain && (
          <>
            <Link
              as={NakedButton}
              onClick={(event) => {
                event.preventDefault();
                tryAgain();
              }}
            >
              try again
            </Link>
            , or{' '}
          </>
        )}
        <ContactLink /> if the problem persists.
      </Alert>
    </Container>
  );
};
