import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

const LazyPropertyFacilities = React.lazy(() =>
  import(
    /* webpackChunkName: "property-facilities" */ './PropertyFacilities'
  ).then((module) => ({ default: module.PropertyFacilities })),
);

export const PropertyFacilities = () => (
  <Suspense fallback={<GenericLoadingIndicator />}>
    <LazyPropertyFacilities />
  </Suspense>
);
