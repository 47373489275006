import pluralize from 'pluralize';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@qga/roo-ui/components';

interface IProps {
  stepsRemaining: number;
  propertyId: number;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const OnboardingLink = ({ stepsRemaining, propertyId, onClick }: IProps) => {
  if (!stepsRemaining) {
    return null;
  }

  return (
    <>
      –{' '}
      <Link
        underline={true}
        as={RouterLink}
        to={`/properties/${propertyId}`}
        onClick={onClick}
        data-testid="onboarding-link"
      >
        {pluralize('step', stepsRemaining, true)} remaining
      </Link>
    </>
  );
};

export default OnboardingLink;
