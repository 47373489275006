import React, { Suspense } from 'react';

import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

function loadUserDetails(key: 'ReviewUserDetails' | 'UpdateUserDetails') {
  return import(/* webpackChunkName: "user-details" */ './asyncBundle').then(
    (module) => ({ default: module[key] }),
  );
}

const LazyReviewUserDetails = React.lazy(() =>
  loadUserDetails('ReviewUserDetails'),
);

export const ReviewUserDetails = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyReviewUserDetails />
    </Suspense>
  );
};

const LazyUpdateUserDetails = React.lazy(() =>
  loadUserDetails('UpdateUserDetails'),
);

export const UpdateUserDetails = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyUpdateUserDetails />
    </Suspense>
  );
};
