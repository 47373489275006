import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from '../../components/GenericLoadingIndicator';

function loadRatePlans(
  key: 'CreateRatePlan' | 'EditRatePlan' | 'RatePlansOverview',
) {
  return import(/* webpackChunkName: "rate-plans" */ './asyncBundle').then(
    (module) => ({ default: module[key] }),
  );
}

const LazyCreateRatePlan = React.lazy(() => loadRatePlans('CreateRatePlan'));
export const CreateRatePlan = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyCreateRatePlan />
    </Suspense>
  );
};

const LazyEditRatePlans = React.lazy(() => loadRatePlans('EditRatePlan'));
export const EditRatePlan = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyEditRatePlans />
    </Suspense>
  );
};

const LazyRatePlansOverview = React.lazy(() =>
  loadRatePlans('RatePlansOverview'),
);
export const RatePlansOverview = () => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyRatePlansOverview />
    </Suspense>
  );
};
