import React from 'react';
import { Redirect } from 'react-router';

import { ActionStates } from 'src/components/ActionStates';
import { ArchivePropertyDocument } from 'src/graphql/mutations/ArchiveProperty';
import { useMutation } from '@apollo/client';

interface IProps {
  propertyId: number;
  propertyName: string;
  children: (handleClick: () => void) => React.ReactNode;
}

const ArchivePropertyAction = ({
  propertyId,
  propertyName,
  children,
}: IProps) => {
  const [archiveProperty, mutationResult] = useMutation(
    ArchivePropertyDocument,
    {
      variables: { id: propertyId },
    },
  );
  return (
    <>
      <ActionStates
        {...mutationResult}
        action={archiveProperty}
        confirmationModal={true}
        confirmationTitle="Archive property"
        confirmationMessage={`Are you sure you want to archive ${propertyName}?`}
        confirmationLabel="Archive"
        loadingMessage="Archiving..."
      >
        {children}
      </ActionStates>

      {mutationResult.data && (
        <Redirect to={`/properties/${propertyId}/details/overview`} />
      )}
    </>
  );
};

export default ArchivePropertyAction;
