import React, { Children, Component } from 'react';
import withSideEffect from 'react-side-effect';

interface IProps {
  children?: React.ReactNode;
  to: string;
  from?: string;
  push?: boolean;
}

class Base extends Component<IProps> {
  public render() {
    if (!this.props.children) {
      return null;
    }

    return Children.only(this.props.children);
  }
}

const reducePropsToState = (propsList: IProps[]) => {
  const innermostProps = propsList[propsList.length - 1];
  if (innermostProps) {
    return {
      push: innermostProps.push,
      to: innermostProps.to,
    };
  }
};

const handleStateChangeOnClient = (props: Partial<IProps> | undefined) => {
  if (props && props.to) {
    props.push
      ? window.location.assign(props.to)
      : window.location.replace(props.to);
  }
};

export const HttpRedirect = withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient,
)(Base);

HttpRedirect.displayName = 'HttpRedirect';
