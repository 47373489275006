import { Label, Box, Input, InputProps } from '@qga/roo-ui/components';
import { useField, FieldHookConfig } from 'formik';
import React, {
  useMemo,
  useCallback,
  PropsWithChildren,
  FocusEventHandler,
  Ref,
} from 'react';
import uniqueId from 'lodash/uniqueId';
import toString from 'lodash/toString';
import { useForm } from 'src/hooks/useForm';
import ErrorMessage from 'src/fields/ErrorMessage';
import Note from 'src/fields/Note';
import useDebounceInput from 'src/hooks/useDebounceInput';

type FieldProps<T> = {
  as?: React.ElementType<any>;
  customRef?: Ref<HTMLInputElement>;
  label: string;
  disabled?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  underline?: boolean;
  hideError?: boolean;
  mb?: number;
  note?: string;
  width?: number;
  onChange?: (name: string, value: T | null) => void;
  format?: (value: T | string | null | undefined) => string;
  formatDebounceDelay?: number;
  parse?: (value: string | null) => T;
  fieldType?: string;
  suppressTracking?: boolean;
  suppressAnalytics?: boolean;
  step?: string;
};

export type Props<T = string> = FieldProps<T> &
  Omit<FieldHookConfig<T>, keyof FieldProps<T>> &
  Omit<InputProps, keyof FieldProps<T>>;

function Field<T = string>({
  customRef,
  disabled,
  hideError,
  hideLabel,
  label,
  note,
  width,
  onChange,
  onBlur,
  placeholder,
  children,
  mb = 6,
  underline = true,
  as = Input,
  parse = (value) => toString(value) as unknown as T,
  format = (value) => toString(value),
  formatDebounceDelay,
  fieldType = 'string',
  suppressTracking = false,
  suppressAnalytics = false,
  step,
  ...props
}: PropsWithChildren<Props<T>>) {
  const Component = as;

  const [field, meta, fieldHelpers] = useField<T>(props);
  const { name } = field;

  const error = Boolean(meta.touched && meta.error);

  const id = useMemo(() => uniqueId(name), [name]);

  const { onFieldBlurred } = useForm();

  const [value, setValue] = useDebounceInput(
    format(field.value),
    (newValue) => {
      // Parse the value from the input
      const parsedValue = parse(newValue);

      // If the value hasn't changed, we can skip the update
      if (field.value === parsedValue) return;

      // Callback if provided
      if (onChange) onChange(name, parsedValue);

      field.onChange(name)(toString(parsedValue));
      fieldHelpers.setValue(parsedValue);
    },
    formatDebounceDelay,
  );

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value);
    },
    [setValue],
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      field.onBlur(event);
      if (onBlur) {
        onBlur(event);
      }
      if (!suppressAnalytics) {
        onFieldBlurred({
          name: label,
          type: fieldType,
          value: suppressTracking ? undefined : value,
          step,
        });
      }
    },
    [
      field,
      onBlur,
      label,
      onFieldBlurred,
      suppressTracking,
      suppressAnalytics,
      fieldType,
      value,
      step,
    ],
  );

  return (
    <Box mb={mb} width={width}>
      {/* Hidden labels must have 0 width of they create extra horizontal whitespace. 100% width is the default width */}
      <Label hidden={hideLabel} htmlFor={id} width={hideLabel ? '0' : '100%'}>
        {label}
      </Label>

      {note && <Note>{note}</Note>}

      <Component
        {...props}
        {...field}
        ref={customRef}
        id={id}
        disabled={disabled}
        error={error}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        underline={underline}
        value={value}
        mb={0}
      >
        {children}
      </Component>

      {!hideError && (
        <ErrorMessage
          mt={3}
          name={name}
          label={label}
          type={fieldType}
          step={step}
        />
      )}
    </Box>
  );
}

export default Field;
