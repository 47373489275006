import React from 'react';
import { Text, Truncate } from '@qga/roo-ui/components';

interface IProps {
  id: number;
  name: string;
}

const PropertySummary = ({ id, name }: IProps) => (
  <Truncate as="div" mr={8} mb={0}>
    <Text lineHeight="tight" title={name}>
      {name}
    </Text>

    <br />

    <Text fontSize="xs" color="blueGrey.slateGrey">
      ID {id}
    </Text>
  </Truncate>
);

export default PropertySummary;
