import { useCallback, useState } from 'react';
import { captureException } from '@sentry/browser';
import { useMutation } from '@apollo/client';
import {
  ValidateTokenDocument,
  ValidateTokenMutation,
} from 'src/graphql/mutations/ValidateToken';
import { config } from 'src/config';

// const IS_ENABLED = process.env.REACT_APP_ENVIRONMENT === 'production';
const IS_ENABLED = false;

const useRecaptcha = () => {
  const [hasError, setHasError] = useState<boolean>();
  const [validate] = useMutation(ValidateTokenDocument);

  const getRecaptcha = useCallback(() => {
    return new Promise<(typeof window)['grecaptcha']>((resolve, reject) => {
      if (window?.grecaptcha) {
        return resolve(window.grecaptcha);
      }
      let hasTimedOut = false;
      const timeoutId = setTimeout(() => {
        hasTimedOut = true;
      }, 5000);
      const intervalId = setInterval(() => {
        if (hasTimedOut) {
          clearInterval(intervalId);
          return reject('grecaptcha did not load within 5s');
        }
        if (window?.grecaptcha) {
          clearTimeout(timeoutId);
          clearInterval(intervalId);
          return resolve(window.grecaptcha);
        }
      }, 50);
    });
  }, []);

  const getRecaptchaWhenReady = useCallback(async () => {
    const grecaptcha = await getRecaptcha();
    return new Promise<typeof grecaptcha>((ready, reject) => {
      let hasTimedOut = false;
      const timeoutId = setTimeout(() => {
        hasTimedOut = true;
        reject(new Error('grecaptcha was not ready within 5s'));
      }, 5000);
      grecaptcha.ready(() => {
        if (!hasTimedOut) {
          clearTimeout(timeoutId);
          ready(grecaptcha);
        }
      });
    });
  }, [getRecaptcha]);

  const getToken = useCallback(
    async (action: string) => {
      const grecaptcha = await getRecaptchaWhenReady();
      return await grecaptcha.execute(config.REACT_APP_RECAPTCHA_SITE_KEY, {
        action,
      });
    },
    [getRecaptchaWhenReady],
  );

  const validateAction = async (
    action: string,
  ): Promise<ValidateTokenMutation> => {
    if (!IS_ENABLED) {
      return { validateToken: { success: true, errorCodes: [] } };
    }
    const errorResponse = {
      validateToken: { success: false, errorCodes: ['no-response'] },
    };
    try {
      const token = await getToken(action);
      const { data } = await validate({
        variables: {
          token,
        },
      });
      if (!data) {
        captureException(
          new Error(
            'No data was returned from graph when validating captcha challenge',
          ),
        );
        setHasError(true);
        return errorResponse;
      }
      setHasError(false);
      return data;
    } catch (error) {
      captureException(error);
      setHasError(true);
      return errorResponse;
    }
  };

  return { validateAction, hasRecaptchaError: hasError };
};

export default useRecaptcha;
