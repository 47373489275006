import { useCallback, useLayoutEffect } from 'react';
import { EventPayload } from './payloadTypes';

declare global {
  interface Window {
    dataLayer?: EventPayload[];
  }
}

function ensureDataLayer() {
  if (!Array.isArray(window.dataLayer)) {
    window.dataLayer = [];
  }
  return window.dataLayer;
}

export function useTrackEvent() {
  useLayoutEffect(() => {
    ensureDataLayer();
  }, []);

  return useCallback<(data: EventPayload) => void>((data) => {
    const dataLayer = ensureDataLayer();
    dataLayer.push(data);
  }, []);
}
