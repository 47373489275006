import { List, ListItem } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

const BareList = styled(List)`
  padding: 0;
  list-style: none;

  ${ListItem} {
    margin: 0;
  }
`;

export default BareList;
