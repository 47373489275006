import { User } from 'src/hooks/useAuth';
import { NavItems } from 'src/layouts/AuthenticatedLayout/components/Nav';

const buildPropertyNav = (propertyId: number) => [
  {
    name: 'Overview',
    to: `/properties/${propertyId}/details/overview`,
  },
  {
    name: 'Location',
    to: `/properties/${propertyId}/details/location`,
  },
  {
    name: 'Facilities',
    to: `/properties/${propertyId}/details/facilities`,
  },
  {
    name: 'Policies',
    to: `/properties/${propertyId}/details/policies`,
  },
  {
    name: 'Commercial terms',
    to: `/properties/${propertyId}/details/commercial-terms`,
  },
  {
    name: 'Other',
    to: `/properties/${propertyId}/details/other`,
  },
];

export default (
  propertyId: number,
  user: User,
  displayInclusions = false,
): NavItems => {
  let items = [
    {
      exact: true,
      icon: 'home',
      name: 'Home',
      to: `/properties/${propertyId}`,
    },
    {
      icon: 'domain',
      name: 'Property',
      to: `/properties/${propertyId}/details`,
      children: buildPropertyNav(propertyId),
    },
    {
      icon: 'hotel',
      name: 'Room types',
      to: `/properties/${propertyId}/room-types`,
    },
  ];

  if (displayInclusions) {
    items.push({
      icon: 'confirmation',
      name: 'Inclusions',
      to: `/properties/${propertyId}/inclusions`,
    });
  }

  items = items.concat([
    {
      icon: 'monetizationOn',
      name: 'Rate plans',
      to: `/properties/${propertyId}/rate-plans`,
    },
    {
      icon: 'localOffer',
      name: 'Promotions',
      to: `/properties/${propertyId}/promotions`,
    },
    {
      icon: 'photo',
      name: 'Media library',
      to: `/properties/${propertyId}/images`,
    },
    {
      icon: 'email',
      name: 'Key contacts',
      to: `/properties/${propertyId}/contacts`,
      children: [
        {
          name: 'Primary contact',
          to: `/properties/${propertyId}/contacts/primary`,
        },
        {
          name: 'Reservations',
          to: `/properties/${propertyId}/contacts/reservations`,
        },
        {
          name: 'Billing',
          to: `/properties/${propertyId}/contacts/billing`,
        },
      ],
    },
    {
      icon: 'creditCard',
      name: 'Reservations',
      to: `/properties/${propertyId}/reservations`,
    },
  ]);

  if (!user.isStaff) {
    return items;
  }

  return [
    ...items,
    {
      icon: 'person',
      name: 'User access',
      to: `/properties/${propertyId}/user`,
    },
    {
      icon: 'watchLater',
      name: 'Trails',
      to: `/properties/${propertyId}/trails`,
    },
    {
      icon: 'lock',
      name: 'Admin',
      to: '/admin',
    },
  ];
};
