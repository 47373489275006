import * as yup from 'yup';

const baseSchema = yup.object().shape({
  password: yup
    .string()
    .required('Enter your password')
    .min(8, 'Minimum 8 characters')
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-])/,
      'Password does not meet strength requirement',
    )
    .ensure(),
  passwordConfirmation: yup
    .string()
    .required('Enter your password')
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .ensure(),
});

export const formSchemaEnhanced = baseSchema.shape({
  email: yup.string(),
  code: yup
    .string()
    .label('Verification code')
    .required('Enter your verification code')
    .length(6)
    .ensure(),
});

export const formSchema = baseSchema.shape({
  email: yup
    .string()
    .required('Enter your email')
    .email('Enter a valid email')
    .ensure(),
  code: yup.string(),
});
