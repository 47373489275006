import { keyframes } from '@emotion/core';
import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { rem } from 'polished';
import React from 'react';
import { themeGet } from 'styled-system';
import Portal from '@reach/portal';

import { Alert, AlertProps } from '@qga/roo-ui/components';

export interface IFixedAlertProps extends AlertProps {
  exiting?: boolean;
}

export const EXITING_TIMEOUT = 500;

const Wrapper = styled.div`
  position: fixed;
  z-index: ${themeGet('zIndices.alert')};
  width: 100%;
  top: ${themeGet('space.8')};
  left: 0;
  text-align: center;
`;

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const StyledAlert = styled(({ exiting, ...rest }) => (
  <Alert {...rest} />
))<IFixedAlertProps>`
  min-width: ${rem('400px')};
  display: inline-block;
  box-shadow: ${themeGet('shadows.alert')};
  animation: ${fadeInDown} ${EXITING_TIMEOUT}ms ease-in-out;

  ${(props) =>
    props.exiting &&
    css`
      animation: ${fadeOut} ${EXITING_TIMEOUT}ms ease-in-out;
    `};
`;

StyledAlert.defaultProps = {
  exiting: false,
};

const FixedAlert = (props: IFixedAlertProps) => (
  <Portal>
    <Wrapper>
      <StyledAlert {...props} />
    </Wrapper>
  </Portal>
);

export default FixedAlert;
