import { useCallback } from 'react';
import { FormikConfig } from 'formik';
import { Schema } from 'yup';
import isNull from 'lodash/isNull';
import mapObj from 'map-obj';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { formatISODate } from 'src/lib/date';
import isDevelopment from 'src/lib/isDevelopment';
import { ApolloError } from '@apollo/client';

export type OnSubmit<V = any> = (values: V) => Promise<void> | void;

type Formatter<V = any> = (values: V) => V;

function parseErrors(error: unknown): string[] {
  if (error instanceof ApolloError) {
    if (error.networkError) {
      return ['Please check the form and re-submit.'];
    }
    if (error.graphQLErrors.length) {
      return error.graphQLErrors.flatMap((graphQlError) => {
        const errors = graphQlError.extensions?.response?.body?.errors ?? [];
        const message =
          graphQlError.extensions?.response?.body?.message ??
          graphQlError.message;
        return [...errors, message];
      });
    }
    return [error.message];
  } else if (error instanceof Error) {
    return [error.message];
  }
  return ['An unknown error occurred'];
}

type SubmitHandler<T> = FormikConfig<T>['onSubmit'];
export function useHandleSubmit<T>(
  formName: string,
  onSubmit: OnSubmit<T>,
  format: Formatter = (values) => values,
  successCallback?: () => void,
): SubmitHandler<T> {
  const { trackFormSubmitted, trackFormError } = useAnalytics();
  return useCallback<SubmitHandler<T>>(
    async (values, actions) => {
      actions.setStatus(null);
      try {
        await onSubmit(format(values));
        // Confirm - should this track when the form is submitted (sent), or only on success (accepted)
        trackFormSubmitted({ formName });
        actions.setStatus({ success: true });

        if (successCallback) {
          successCallback();
        }
      } catch (error) {
        if (isDevelopment) console.error(error);
        trackFormError({ formName, error });
        const errors = parseErrors(error);
        actions.setStatus({ errors });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [
      onSubmit,
      format,
      trackFormSubmitted,
      formName,
      successCallback,
      trackFormError,
    ],
  );
}

export const initialValues = (
  schema: Schema<any>,
  values: any = {},
  radioBooleanFields: string[] = [],
): any => {
  const initialValues = mapObj<any, any, string>(
    values,
    (key, value) => {
      if (radioBooleanFields.includes(key as string)) {
        return [key, value ? 'yes' : 'no'];
      }

      if (isNull(value)) {
        return [key, undefined];
      }

      return [key, value];
    },
    { deep: true },
  );

  return schema.cast(initialValues);
};

type Options = {
  schema?: Schema<any>;
  omitFields?: string[];
  dateFields?: string[];
  customizer?: (values: any) => any;
};

export const createFormValuesFormatter =
  ({
    schema,
    omitFields = [],
    dateFields = [],
    customizer = (values) => values,
  }: Options = {}) =>
  (formValues: any) => {
    let values = schema ? schema.cast(formValues) : formValues;

    values = customizer(values);

    values = mapObj<any, any, string>(
      values,
      (key, value) => {
        if (omitFields.includes(key as string)) {
          return [key, undefined];
        }

        if (dateFields.includes(key as string)) {
          if (!value) return [key, value];
          return [key, formatISODate(value)];
        }

        if (value === '') {
          return [key, null];
        }

        return [key, value];
      },
      { deep: true },
    );

    return values;
  };
