import { useCallback, useEffect } from 'react';

export function useUnloadPrompt({ when }: { when: boolean }) {
  const preventUnload = useCallback((event: Event) => {
    event.preventDefault();
    event.returnValue = false;
  }, []);

  useEffect(() => {
    if (!when) {
      return;
    }
    window.addEventListener('beforeunload', preventUnload);
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [preventUnload, when]);
}
