import React from 'react';
import { Global } from '@emotion/core';
import css from '@emotion/css/macro';

export const DisableInputSpinners = () => (
  <Global
    styles={css`
      input[type='number'] {
        -moz-appearance: textfield;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
  />
);
