/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../__generated__/graphqlTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
const usedFragments: string[] = [];
export type ContactForm_ContactFragment = {
  __typename?: 'Contact';
  id: number;
  name: string;
  position: string | null;
  email: string;
  phone: string | null;
  confirmationEmails: boolean | null;
  vccInEmail: boolean | null;
};

export type ContactForm_PropertyFragment = {
  __typename?: 'Property';
  id: number;
  billingContact: {
    __typename?: 'Contact';
    id: number;
    name: string;
    position: string | null;
    email: string;
    phone: string | null;
    confirmationEmails: boolean | null;
    vccInEmail: boolean | null;
  } | null;
  primaryContact: {
    __typename?: 'Contact';
    id: number;
    name: string;
    position: string | null;
    email: string;
    phone: string | null;
    confirmationEmails: boolean | null;
    vccInEmail: boolean | null;
  } | null;
  reservationsContact: {
    __typename?: 'Contact';
    id: number;
    name: string;
    position: string | null;
    email: string;
    phone: string | null;
    confirmationEmails: boolean | null;
    vccInEmail: boolean | null;
  } | null;
};

export type ManageContactsQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['ID']['input'];
}>;

export type ManageContactsQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'Viewer';
    id: string;
    property: {
      __typename?: 'Property';
      id: number;
      billingContact: {
        __typename?: 'Contact';
        id: number;
        name: string;
        position: string | null;
        email: string;
        phone: string | null;
        confirmationEmails: boolean | null;
        vccInEmail: boolean | null;
      } | null;
      primaryContact: {
        __typename?: 'Contact';
        id: number;
        name: string;
        position: string | null;
        email: string;
        phone: string | null;
        confirmationEmails: boolean | null;
        vccInEmail: boolean | null;
      } | null;
      reservationsContact: {
        __typename?: 'Contact';
        id: number;
        name: string;
        position: string | null;
        email: string;
        phone: string | null;
        confirmationEmails: boolean | null;
        vccInEmail: boolean | null;
      } | null;
    } | null;
  } | null;
};

export const ContactForm_ContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmationEmails' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vccInEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactForm_ContactFragment, unknown>;
export const ContactForm_PropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservationsContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmationEmails' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vccInEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactForm_PropertyFragment, unknown>;
export const ManageContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'propertyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'property' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'propertyId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactForm_Property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmationEmails' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vccInEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactForm_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservationsContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactForm_Contact' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageContactsQuery, ManageContactsQueryVariables>;
