import styled from '@emotion/styled/macro';
import { rem } from 'polished';
import React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Hide,
  Icon,
  Image,
  NakedButton,
  NakedButtonProps,
  Link,
} from '@qga/roo-ui/components';
import { qantasTail } from '@qga/roo-ui/logos';
import { themeGet } from 'styled-system';

import Badge from 'src/primitives/Badge';
import BareList from 'src/primitives/BareList';

import NavItem, { INavItemProps } from './components/NavItem';

export type NavItems = INavItemProps[];

interface IWrapperProps extends BoxProps {
  open: boolean;
}

interface IProps extends IWrapperProps {
  items: NavItems;
  onToggle: () => void;
}

const Wrapper = styled(({ open, ...rest }) => <Box {...rest} />)`
  position: fixed;
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  min-width: ${rem('260px')};
  background-color: ${themeGet('colors.greys.mineShaft')};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: ${themeGet('zIndices.nav')};
  transform: translateX(${(props) => (props.open ? '0' : '-100%')});
  will-change: transform;
  transition: 0.2s transform ease-out;

  ${themeGet('mediaQueries.1')} {
    position: static;
    height: auto;
    width: auto;
    transform: translateX(0);

    @supports (position: sticky) {
      position: sticky;
      height: 100vh;
      top: 0;
    }
  }
`;

const CloseButton = styled(NakedButton)<NakedButtonProps>`
  transition: ${themeGet('transitions.default')};

  &:hover,
  &:focus {
    opacity: 0.5;
    outline: none;
  }
`;

const Header = styled(Flex)`
  position: sticky;
  top: 0;
  height: ${rem('60px')};
  padding: ${themeGet('space.5')};
  align-items: center;
  background: linear-gradient(160deg, ${themeGet('colors.brand.gradient')} 45%);
  color: ${themeGet('colors.white')};

  ${themeGet('mediaQueries.0')} {
    height: ${rem('80px')};
  }
`;

const Nav = ({ open = false, items, onToggle }: IProps) => (
  <Wrapper id="nav" as="nav" open={open}>
    <Header>
      <Link as="a" href="/">
        <Image
          width={[rem('40px'), rem('50px')]}
          src={qantasTail}
          alt="Qantas"
          mr={2}
        />
      </Link>

      <Heading.h4 fontSize={rem('24px')} fontWeight="normal" mb="0">
        Extranet
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
          <Badge bg="white" color="brand.primary" ml={2}>
            Non-prod
          </Badge>
        )}
      </Heading.h4>

      <CloseButton ml="auto" onClick={onToggle}>
        <Hide md={true} lg={true}>
          <Icon name="close" size={25} />
        </Hide>
      </CloseButton>
    </Header>

    <BareList>
      {items.map((props, i) => (
        <NavItem key={i} {...props} />
      ))}
    </BareList>
  </Wrapper>
);

export default Nav;
