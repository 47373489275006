import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useBoolean } from 'react-use';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Paragraph,
} from '@qga/roo-ui/components';
import IconCircle from 'src/primitives/IconCircle';
import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout';
import useAuth from 'src/hooks/useAuth';
import ResetPasswordForm from './components/ResetPasswordForm';
import { useFeatureFlag } from 'src/lib/featureFlags';
import { PasswordResetAlert } from 'src/components/PasswordResetAlert';

const redirectToForgotPassword = () => (
  <Redirect
    to={{
      pathname: '/forgot-password',
      state: {
        notification:
          'Something went wrong, please request a new password reset link.',
      },
    }}
  />
);

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const { confirmResetPassword, signOut } = useAuth();
  const [success, setSuccess] = useBoolean(false);
  const displayPasswordExpiryBanner = useFeatureFlag('password_expiry_banner');
  const displayPasswordEnhancement =
    useFeatureFlag('password_enhancement') === 'on';

  const { code } = queryString.parse(location.search);
  const title = displayPasswordEnhancement
    ? 'Choose a new password'
    : 'Reset your password';

  useEffect(() => {
    if (success) {
      signOut();
    }
  }, [success, signOut]);

  const locationState = location.state as { email: string };
  if (displayPasswordEnhancement && (!locationState || !locationState.email)) {
    return redirectToForgotPassword();
  }

  if (!displayPasswordEnhancement && !code) {
    return redirectToForgotPassword();
  }

  const resetPassword = async (
    email: string,
    code: string,
    password: string,
  ) => {
    await confirmResetPassword(email, String(code), password);
    setSuccess(true);
  };

  return (
    <UnauthenticatedLayout title={title} showBanners>
      {displayPasswordExpiryBanner === 'on' && <PasswordResetAlert />}
      <Container maxWidth="wide">
        <Flex justifyContent="center" flexWrap="wrap">
          <Box width={[1, 1, 5 / 6]}>
            {success ? (
              <>
                <Heading.h2 mt={8} mb={4}>
                  Password successfully reset
                </Heading.h2>

                <Flex alignItems="center" flexWrap="wrap">
                  <IconCircle name="done" size={60} color="ui.success" />

                  <Box width={4 / 5}>
                    <Paragraph ml={4} mb={0}>
                      You have successfully reset your password.
                      <br />
                      Please use the new password when signing in.
                    </Paragraph>
                  </Box>
                </Flex>

                <Box width={[1, 3 / 10]}>
                  <Link to="/">
                    <Button variant="primary" block={true} mt={8}>
                      Sign in
                    </Button>
                  </Link>
                </Box>
              </>
            ) : (
              <>
                <Heading.h2 mt={8} mb={6}>
                  {title}
                </Heading.h2>

                <Box>
                  <ResetPasswordForm
                    valueType={displayPasswordEnhancement ? 'email' : 'code'}
                    value={
                      displayPasswordEnhancement
                        ? locationState.email
                        : String(code)
                    }
                    onResetPassword={resetPassword}
                  />
                </Box>
              </>
            )}
          </Box>
        </Flex>
      </Container>
    </UnauthenticatedLayout>
  );
};
