import { lightbulb, shield } from '@qga/roo-ui/assets';
import { rem } from 'polished';
import React from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  Image,
  Paragraph,
  Hide,
  Container,
} from '@qga/roo-ui/components';

const Banner: React.FC<any> = ({ heading, body, image }) => (
  <Card boxShadow="heavy" width={[1, 1, 10 / 12]} py={[4, 6]} mb={5}>
    <Flex>
      <Image mx={[2, 4]} src={image} size={rem('60px')} />

      <Box mx={[2, 4]}>
        <Heading.h3>{heading}</Heading.h3>

        <Paragraph color="greys.steel">{body}</Paragraph>
      </Box>
    </Flex>
  </Card>
);

const Banners = () => (
  <Hide xs={true}>
    <Box bg="greys.porcelain">
      <Container maxWidth="wide">
        <Flex alignItems="center" pt={9} pb={4} flexDirection="column">
          <Banner
            heading="Create a rate plan with multiple rooms faster"
            body="You can now select multiple room types when creating a rate plan."
            image={lightbulb}
          />

          <Banner
            heading="Your security is important"
            body="Please keep your username and password confidential and never share them, as your sensitive hotel information and that of your guests needs to be protected."
            image={shield}
          />
        </Flex>
      </Container>
    </Box>
  </Hide>
);

export default Banners;
