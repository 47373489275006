import React from 'react';
import { Flex, LoadingIndicator } from '@qga/roo-ui/components';

import Overlay from 'src/primitives/Overlay';

export const LoadingOverlay = () => (
  <Overlay data-testid="loading-overlay">
    <Flex
      minHeight="100vh"
      bg="white"
      flexDirection="column"
      justifyContent="center"
    >
      <LoadingIndicator color="greys.alto" />
    </Flex>
  </Overlay>
);
