import { Alert, Link, NakedButton } from '@qga/roo-ui/components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import React from 'react';

import { FlashAlert } from 'src/components/FlashAlert';
import useAuth from 'src/hooks/useAuth';

const EmailVerificationNotice: React.FC = () => {
  const { user, verifyEmail } = useAuth();

  const location = useLocation();

  const [state, resendConfirmation] = useAsyncFn(async () => {
    await verifyEmail();
    return true;
  }, []);

  return (
    <>
      {state.error && (
        <FlashAlert variant="error" data-testid="error">
          <strong>Couldn&apos;t resend the confirmation email.</strong> Please
          try again later.
        </FlashAlert>
      )}

      {state.value && (
        <FlashAlert variant="success" data-testid="success">
          A confirmation email has been sent to <strong>{user?.email}</strong>.
        </FlashAlert>
      )}

      <Alert variant="error" contained={true} mb={0}>
        <strong>Confirm your email address.</strong> A confirmation email was
        sent to {user?.email}.<br />
        <NakedButton onClick={resendConfirmation} disabled={state.loading}>
          <Link as="span" underline={true}>
            Resend confirmation
          </Link>
        </NakedButton>{' '}
        &bull;{' '}
        <Link
          as={RouterLink}
          underline={true}
          to={{
            pathname: '/user-details/update',
            state: { from: location },
          }}
        >
          Update email address
        </Link>
      </Alert>
    </>
  );
};

export default EmailVerificationNotice;
