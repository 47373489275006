export const maskEmailAddress = (value: string): string => {
  const MASKCHAR = '*';
  const SPLITCHAR = '@';
  const [prefix, suffix] = value.split(SPLITCHAR);
  let maskedValue = '';

  for (let i = 0; i < prefix.length; i++) {
    if (i === 0 || i === prefix.length - 1) {
      maskedValue += prefix[i];
    } else {
      maskedValue += MASKCHAR;
    }
  }
  return maskedValue + SPLITCHAR + suffix;
};
