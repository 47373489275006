import { captureException } from '@sentry/browser';
import React from 'react';

import { ErrorPage } from 'src/pages/ErrorPage';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  public state = { hasError: false };

  public componentDidCatch(error: Error) {
    captureException(error);
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
