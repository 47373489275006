import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import join from 'url-join';

import { config } from 'src/config';
import buildUrl from 'src/lib/buildUrl';

const buildBaseUrl = (path: string, query?: { [key: string]: any }) => {
  const snakeCaseQuery = mapKeys(query, (_, key) => snakeCase(key));

  return buildUrl(config.REACT_APP_HPP_URI, path, snakeCaseQuery);
};

export const buildHppAssetUrl = buildBaseUrl;

export default (path: string, query?: { [key: string]: any }) =>
  buildBaseUrl(join('/extranet', path), query);
