import React from 'react';
import { Redirect } from 'react-router';

import { ActionStates } from 'src/components/ActionStates';
import { DeletePropertyDocument } from 'src/graphql/mutations/DeleteProperty';
import { useMutation } from '@apollo/client';

interface IProps {
  propertyId: number;
  propertyName: string;
  children: (handleClick: () => void) => React.ReactNode;
}

const DeletePropertyAction = ({
  propertyId,
  propertyName,
  children,
}: IProps) => {
  const [deleteProperty, mutationResult] = useMutation(DeletePropertyDocument, {
    variables: { id: propertyId },
  });
  return (
    <>
      <ActionStates
        {...mutationResult}
        action={deleteProperty}
        confirmationModal={true}
        confirmationTitle="Delete property"
        confirmationMessage={`Are you sure you want to delete ${propertyName}?`}
        confirmationLabel="Delete"
        loadingMessage="Deleting..."
      >
        {children}
      </ActionStates>

      {mutationResult.data && <Redirect to="/" />}
    </>
  );
};

export default DeletePropertyAction;
