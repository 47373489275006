import css from '@emotion/css/macro';
import Downshift from 'downshift';
import partition from 'lodash/partition';
import React, { ReactElement } from 'react';
import { Manager, Popper, PopperChildrenProps, Reference } from 'react-popper';
import {
  Box,
  BoxProps,
  NakedButton,
  NakedButtonProps,
} from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled/macro';

const DropdownWrapper = styled(Box)<BoxProps>`
  background: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.dropdown')};
  border-radius: ${themeGet('radii.default')};
  padding: ${themeGet('space.2')} 0;
  z-index: ${themeGet('zIndices.popover')};
`;

interface IDropdownItemProps extends NakedButtonProps {
  highlighted?: boolean;
  redTextHighLightMode?: boolean;
}

const DropdownItem = styled(
  ({ highlighted, redTextHighLightMode, ...props }) => (
    <NakedButton {...props} />
  ),
)<IDropdownItemProps>`
  padding: ${themeGet('space.2')} ${themeGet('space.6')};
  color: ${themeGet('colors.greys.charcoal')};
  display: block;
  width: 100%;
  text-align: left;
  text-decoration: none;

  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${themeGet('colors.greys.porcelain')(props)};
      color: ${props.redTextHighLightMode
        ? themeGet('colors.red')(props)
        : undefined};
    `}
`;

interface IProps {
  placement: PopperChildrenProps['placement'];
  children: React.ReactNode[];
}

export const Dropdown = ({ placement, children, ...props }: IProps) => (
  <Manager>
    <Downshift {...props}>
      {({ isOpen, getToggleButtonProps, highlightedIndex, getItemProps }) => {
        const childrenArray = React.Children.toArray(children) as Array<
          ReactElement<any>
        >;

        const [items, toggle] = partition(
          childrenArray,
          (child) => child.type === Dropdown.item,
        );

        return (
          <div>
            <Reference>
              {({ ref }) => (
                <NakedButton {...getToggleButtonProps()} ref={ref}>
                  {toggle}
                </NakedButton>
              )}
            </Reference>

            <Popper placement={placement} strategy="fixed">
              {({ ref, style }) =>
                isOpen && (
                  <DropdownWrapper ref={ref} style={style}>
                    {items.map((item, index) =>
                      React.cloneElement(
                        item,
                        getItemProps({
                          highlighted: highlightedIndex === index,
                          item: index,
                          key: index,
                          ...item.props,
                        }),
                      ),
                    )}
                  </DropdownWrapper>
                )
              }
            </Popper>
          </div>
        );
      }}
    </Downshift>
  </Manager>
);

Dropdown.defaultProps = {
  placement: 'bottom',
};

Dropdown.item = DropdownItem;
Dropdown.item.displayName = 'Dropdown.item';
