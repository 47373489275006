import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

const LazyPropertyLocation = React.lazy(() =>
  import(
    /* webpackChunkName: "property-location" */ './PropertyLocationPage'
  ).then((module) => ({
    default: module.PropertyLocationPage,
  })),
);

export const PropertyLocation = () => (
  <Suspense fallback={<GenericLoadingIndicator />}>
    <LazyPropertyLocation />
  </Suspense>
);
