import React from 'react';

import { ActionStates } from 'src/components/ActionStates';
import { UnpublishPropertyDocument } from 'src/graphql/mutations/UnpublishProperty';
import { useMutation } from '@apollo/client';

interface IProps {
  propertyId: number;
  propertyName: string;
  children: (handleClick: () => void) => React.ReactNode;
}

const UnpublishPropertyAction = ({
  propertyId,
  propertyName,
  children,
}: IProps) => {
  const [unpublishProperty, mutationResult] = useMutation(
    UnpublishPropertyDocument,
    {
      variables: { id: propertyId },
    },
  );

  return (
    <ActionStates
      {...mutationResult}
      action={unpublishProperty}
      confirmationModal={true}
      confirmationTitle="Unpublish property"
      confirmationMessage={`Are you sure you want to unpublish ${propertyName}?`}
      confirmationLabel="Unpublish"
      loadingMessage="Unpublishing..."
    >
      {children}
    </ActionStates>
  );
};

export default UnpublishPropertyAction;
