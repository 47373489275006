import { BackgroundImage } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

const FlexBackgroundImage = styled(BackgroundImage)`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
`;

FlexBackgroundImage.displayName = 'FlexBackgroundImage';

export default FlexBackgroundImage;
