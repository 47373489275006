import React, { useMemo } from 'react';
import { Box, Flex, Heading, Icon, ListItem } from '@qga/roo-ui/components';
import { PasswordRequirementCard, PasswordRequirementList } from './primitives';

type Props = {
  input: {
    password?: string;
    passwordConfirmation?: string;
  };
};

type InputValidator = (a?: string, b?: string) => boolean;

type ValidatorName =
  | 'uppercase'
  | 'lowercase'
  | 'number'
  | 'special-char'
  | 'min-length'
  | 'pwd-match';

const validators: [
  name: ValidatorName,
  validate: InputValidator,
  label: React.ReactNode,
][] = [
  [
    'uppercase',
    (value = '') => /(?=.*[A-Z])/g.test(value),
    '1 uppercase letter',
  ],
  [
    'lowercase',
    (value = '') => /(?=.*[a-z])/g.test(value),
    '1 lowercase letter',
  ],
  ['number', (value = '') => /(?=.*[0-9])/g.test(value), '1 number'],
  [
    'special-char',
    (value = '') => /(?=.*[$&+,:;=?@#|'<>.^*()%!_/\\~'[\]{}-])/g.test(value),
    '1 special character (eg: !@#$)',
  ],
  [
    'min-length',
    (value) => Boolean(value?.length ?? 0 >= 8),
    'Minimum 8 characters',
  ],
  ['pwd-match', (a, b) => !!a && a === b, 'Passwords match'],
];

const iconProps = {
  name: 'check',
  size: 20,
  mr: 2,
};

export const PasswordRequirement = ({ input = {} }: Props) => {
  const { password, passwordConfirmation } = input;

  const items = useMemo(() => {
    return validators.map(([name, validate, label]) => {
      const isValid = validate(password, passwordConfirmation);
      const testId = isValid ? `${name}-icon-pass` : `${name}-icon`;
      const color = isValid ? 'status.green' : 'greys.dusty';
      return (
        <ListItem key={name} mb={0}>
          <Icon {...iconProps} data-testid={testId} color={color} />
          {label}
        </ListItem>
      );
    });
  }, [password, passwordConfirmation]);

  return (
    <PasswordRequirementCard>
      <Box>
        <Flex>
          <Icon name="lock" mr={2} />
          <Heading.h4 mb={0}>Secure password requirements</Heading.h4>
        </Flex>
        <PasswordRequirementList flush={true}>{items}</PasswordRequirementList>
      </Box>
    </PasswordRequirementCard>
  );
};
