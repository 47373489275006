import { PasswordInput } from '@qga/roo-ui/components';
import React from 'react';

import Field, { Props } from 'src/fields/Field';

const PasswordField: React.FC<Props> = (props) => (
  <Field autoComplete="current-password" {...props} suppressTracking />
);

PasswordField.defaultProps = {
  as: PasswordInput,
};

export default PasswordField;
