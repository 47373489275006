import { stringify } from 'query-string';
import join from 'url-join';

export default (
  uri: string,
  path: string,
  query: { [key: string]: any } = {},
) => {
  const params = stringify(query);

  return join(uri, path, params && `?${params}`);
};
