import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { usePropertyIdAlwaysInt } from 'src/hooks/usePropertyId';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { PropertyHeaderItemsDocument } from 'src/graphql/queries/PropertyHeaderItems';
import buildNavItems from 'src/layouts/PropertyLayout/lib/buildNavItems/buildNavItems';
import { useInclusionsFeatureToggle } from 'src/hooks/useInclusionsFeatureToggle';

export function usePropertyLayoutData() {
  const [navOpen, setNavOpen] = useState(false);
  const propertyId = usePropertyIdAlwaysInt();
  const user = useCurrentUser();
  const [displayInclusions] = useInclusionsFeatureToggle();
  const navItems = useMemo(
    () => buildNavItems(propertyId, user, displayInclusions),
    [propertyId, user, displayInclusions],
  );
  const handleToggleNav = useCallback(() => {
    setNavOpen(!navOpen);
  }, [setNavOpen, navOpen]);
  const { data, loading } = useQuery(PropertyHeaderItemsDocument, {
    variables: {
      id: propertyId,
    },
  });
  const property = data?.property;

  return {
    handleToggleNav,
    loading,
    navItems,
    navOpen,
    property,
    propertyId,
    user,
  };
}
