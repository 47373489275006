/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../__generated__/graphqlTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
const usedFragments: string[] = [];
export type FeatureFlags_PropertyFragment = {
  __typename?: 'Property';
  id: number;
  inventoryProvider: Types.InventoryProvider;
  integrationPartner: string;
};

export const FeatureFlags_PropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeatureFlags_Property' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Property' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integrationPartner' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeatureFlags_PropertyFragment, unknown>;
