import React, { ComponentProps } from 'react';

import { GenericError } from 'src/components/GenericError';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

type Props = {
  error?: Error;
  loading: boolean;
  refetch?: ComponentProps<typeof GenericError>['tryAgain'];
};

export const QueryStates: React.FC<Props> = ({
  loading,
  error,
  refetch,
  children,
}) => {
  if (loading) return <GenericLoadingIndicator />;
  if (error) return <GenericError tryAgain={refetch} />;
  return <>{children}</>;
};
