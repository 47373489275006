import { Field, FieldProps, FormikProps } from 'formik';
import get from 'lodash/get';
import over from 'lodash/over';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { Box, Checkbox, Label, Radio } from '@qga/roo-ui/components';
import { useForm } from 'src/hooks/useForm';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
type SyntheticEvent = React.SyntheticEvent<HTMLInputElement>;

type RadioProps = React.ComponentProps<typeof Radio>;

type RadioFieldProps = {
  component?: typeof Checkbox | typeof Radio;
  hideLabel?: boolean;
  label: React.ReactNode;
  name: string;
  mb?: number;
  checked?: (
    formValue: string | boolean,
    value?: RadioProps['value'],
  ) => boolean;
  onBlur?: (event: SyntheticEvent, form: FormikProps<any>) => void;
  onChange?: (event: ChangeEvent, form: FormikProps<any>) => void;
  value?: string | number;
  step?: string;
};

type Props = Omit<RadioProps, keyof RadioFieldProps> & RadioFieldProps;

const RadioField = (props: Props) => {
  const { onFieldBlurred } = useForm();
  const inputId = uniqueId(props.name);
  const {
    checked = (formValue: string | number, value: string | number) =>
      formValue == value,
    component: Component = Radio,
    hideLabel = false,
    tabIndex = 0,
    type = 'radio',
    mb = 4,
    label,
    name,
    value = '',
    onBlur = () => undefined,
    onChange = () => undefined,
    step,
    ...inputProps
  } = props;

  const onTrackFieldBlured = (isChecked: boolean) => () => {
    onFieldBlurred({ name, type, value: isChecked, step });
  };

  return (
    <Box mb={mb}>
      <Field type={type} name={name} value={value} step={step}>
        {({ field, form }: FieldProps) => {
          const isChecked = checked(get(form.values, name), value);
          return (
            <Label
              textStyle="text"
              htmlFor={inputId}
              mb={0}
              width={hideLabel ? '0' : '100%'}
            >
              {/* Hidden labels must have 0 width of they create extra horizontal whitespace. 100% width is the default width */}
              <Component
                id={inputId}
                {...field}
                {...inputProps}
                tabIndex={tabIndex}
                checked={isChecked}
                onBlur={over([
                  field.onBlur,
                  onBlur,
                  onTrackFieldBlured(isChecked),
                ])}
                onChange={over([field.onChange, onChange])}
              />{' '}
              <span hidden={hideLabel}>{label}</span>
            </Label>
          );
        }}
      </Field>
    </Box>
  );
};

export default RadioField;
